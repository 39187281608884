import React from "react";
import Select, {GroupBase, Props, StylesConfig} from "react-select";

function CustomSelect<Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>>(props: Props<Option, IsMulti, Group>) {

    const selectStyle: StylesConfig<Option, IsMulti> = {
        control: (provided, state) => {
            return {
                ...provided,
                ...{
                    borderColor: "#D5E8FB",
                    borderRadius: 15,
                    padding: '10px 20px',
                    cursor: 'pointer',
                }
            };
        },
        singleValue: (provided, state) => {
            return {
                ...provided,
                ...{
                    fontWeight: 'bold',
                }
            };
        },
        menu: (provided, state) => ({
            ...provided,
            zIndex: 5,
            borderColor: "#D5E8FB",
            borderRadius: 15,
            padding:0,
            overflow: "hidden"
        }),
        option: (styles, {data, isDisabled, isFocused, isSelected}) => {
            return {
                ...styles,
                backgroundColor: isDisabled ? '#dadada' : 'none',
                padding: '10px 20px',
                color: isSelected ? '#FF5858' : '#2E2F3A',
                ":hover": {
                  color: isDisabled ? '#2E2F3A' : "#FF5858",
                },
                fontWeight: 'bold',
                cursor: isDisabled ? 'not-allowed' : 'pointer',
            }
        },
    };

    return (
        <div className={'jq-selectbox'}>
            <Select {...props}
                    classNamePrefix="react-select"
                    styles={selectStyle}/>
        </div>
    );
}


export default CustomSelect;