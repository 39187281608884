import * as React from 'react';
import {toggleExcursionDialog, toggleFreeLessonDialog} from "../../dialog/dialogSlice";
import {useAppDispatch} from "../../../hooks/useAppDispatch";


export const HeaderBlock = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const handleFreeLesson = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        dispatch(toggleFreeLessonDialog());
    };
    const handleExcursion = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        dispatch(toggleExcursionDialog());
    };
    return (
        <div className="headbox">
            <div className="wrapper">
                <h1>Обучаем и развиваем детишек <span>уже более <b>{(new Date().getFullYear())-2005}-ти лет</b></span></h1>

                <div className="headbox__title">Федеральная сеть центров раннего развития и детских садов</div>

                <a data-src="#modal-class" href="#" className="btn btn-yellow" onClick={handleFreeLesson}>Записаться на занятие</a>
                <a data-src="#modal-excursion" href="#" className="btn btn-white" onClick={handleExcursion}>Экскурсия
                    по детскому
                    саду</a>

                <div className="headbox__quote">Только дети знают чего хотят. Они едят руками, потому что так весело,
                    они рисуют на обоях, и делают свою комнату особенной.
                </div>

                <img src="/assets/images/headbox-image.png" alt="" className="headbox-image"/>
            </div>
        </div>
    );
};


