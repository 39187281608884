import {api} from './api';

export const InitialItemEmployee = {
    id: 0,
    name: 'Здесь можете быть Вы',
    position: 'кто вы?',
    experience: '',
    education: 'соответвующее должности',
    text: '',
    image: '',
    created: new Date,
}


export const postsApi = api.injectEndpoints({
    endpoints: build => ({
        getEmployees: build.query<ListResponse<ItemEmployee>, void | QueryArg>({
            query: ({
                        offset,
                        limit,
                        city
                    }: QueryArg) => `/about/employee?offset=${offset}&limit=${limit}&city=${city}&omit=content`,
            providesTags: data =>
                data
                    ? [
                        ...data.results.map(({id}) => ({
                            type: 'Employees' as const,
                            id,
                        })),
                        {type: 'Employees', id: 'EMPLOYEE-LIST'},
                    ]
                    : [...[], {type: 'Employees', id: 'EMPLOYEE-LIST'}],
        }),
        getEmployeesFull: build.query<ListResponse<ItemEmployee>, void | QueryArg>({
            query: ({
                        offset,
                        limit,
                        branch,
                        url
                    }: QueryArg) => {
                        if (url) {
                            return url
                        }
                        return `/about/employee?offset=${offset}&limit=${limit}&branch=${branch}&expand=certificates`
                    },
            providesTags: data =>
                data
                    ? [
                        ...data.results.map(({id}) => ({
                            type: 'Employees' as const,
                            id,
                        })),
                        {type: 'Employees', id: 'EMPLOYEE-FULL-LIST'},
                    ]
                    : [...[], {type: 'Employees', id: 'EMPLOYEE-FULL-LIST'}],
        }),
    }),
});

export const {useGetEmployeesQuery, useGetEmployeesFullQuery} = postsApi;

export const {
    endpoints: {getEmployees, getEmployeesFull},
} = postsApi;
