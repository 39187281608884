import * as React from 'react';
import {useEffect, useState} from 'react';
import {useGetServiceAgeQuery} from "../../../services/services";
import {toggleServiceDialog} from "../../dialog/dialogSlice";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {cropSrc} from "../../../utils/utils";
import {useInView} from "react-intersection-observer";
import clsx from "clsx";
import {useAppInview} from "../../../hooks/useAppInView";

export const AgeItem = (props: { item: ItemAge }): JSX.Element => {
    const [maxIndex, setmaxIndex] = useState(3);
    const item = props.item;
    const dispatch = useAppDispatch();
    const src_image = cropSrc(item.image, 250, 250)
    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        dispatch(toggleServiceDialog({pid: item.id}))
    };

    const handleShowClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setmaxIndex(0)
    };
    const { ref, inView, entry } = useAppInview();

    return (
        <div ref={ref}
             className={clsx("headlist__block animate animateY", inView && 'animated')}
            >
            <div className="headlist__block-img flex align-item">
                <img src={src_image} alt={item.name}/></div>
            <div className="headlist__block-age">{item.name}</div>
            {
                (item.services.length) ?
                    <React.Fragment>
                        <ul>
                            {item.services.map((serviceItem, index) => {
                                if (maxIndex && index >= maxIndex) {
                                    if (index == maxIndex) {
                                        return <li key={index}>
                                            <a href={'#'} onClick={(e) => handleShowClick(e)}>еще</a>
                                        </li>
                                    }
                                    return null
                                }
                                return (
                                    <li key={index}>
                                        {
                                            (serviceItem.url) ?
                                                <a href={serviceItem.url} target={"_blank"}>{serviceItem.name}</a> :
                                                <React.Fragment>{serviceItem.name}</React.Fragment>
                                        }

                                    </li>
                                );
                            })}
                        </ul>

                        <a data-src="#modal-choose" href="#" className="headlist-btn"
                           onClick={(e) => handleClick(e)}
                        >Подобрать</a>
                    </React.Fragment> : null
            }

        </div>
    )
};

interface ServiceBlockProp {
    title: string,
    limit: number,
    branch?: number,
    city?: number,
}


export const ServiceBlock = (props: ServiceBlockProp): JSX.Element => {
    const {title, limit, branch, city} = props
    const [objectList, setList] = useState([]);
    const {data: object, isLoading, isSuccess, isFetching, isError} = useGetServiceAgeQuery({
        city: city,
        branch: branch
    });

    useEffect(() => {
        if (isSuccess) {
            setList([...object]);
        }
    }, [object]);

    const { ref, inView, entry } = useAppInview();

    return (
        <div className="headlist">
            <div className="wrapper">
                <div ref={ref}>
                    <h2 className={clsx("animate animateY", inView && 'animated')} dangerouslySetInnerHTML={{__html: title}}/>
                </div>
                <div className="headlist__box flex">
                    {objectList.map((ageItem, index) => {
                        return (
                            <AgeItem key={ageItem.id} item={ageItem}/>
                        );
                    })}

                </div>
            </div>
        </div>
    );
};
ServiceBlock.defaultProps = {
    title: 'Выберите возраст малыша <br />и подберите подходящие занятия',
    limit: 100,
}

