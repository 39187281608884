import * as React from 'react';
import {useBranchContext} from "../../../context/BranchContext";
import {FormCallBack} from "../../base/form/FormCallBack";


export const CallBackDialog = (): JSX.Element => {
    const {branch, city} = useBranchContext();
    return (
        <div className="modal__callback flex">
            <FormCallBack branch={branch} tp={60} buttonName={'Заказать звонок'} children={
                <div className="modal__callback-box">
                    <h2>Заказать <span>обратный звонок</span> </h2>
                    <div className="modal__callback-title">Укажите Ваши контактные данные и мы свяжемся с Вами в ближайшее время!
                    </div>

                    <img src="/assets/images/modal-image2.jpg" alt="" className="modal__callback-img hide-lsee-lg hide-lsee-md"/>
                </div>
            }/>
        </div>
    );
};


