import * as React from 'react';
import {FormFio} from "../../base/form/FormFio";
import {FormFioProp} from "../../base/form/FormFioSlice";

interface ServiceDialog2Prop {
    pid: number
    params: FormFioProp
}

export const ServiceDialog2 = (props: ServiceDialog2Prop): JSX.Element => {
    const {pid, params} = props;
    const {age, service, branch, object} = params
    return (
        <FormFio age={age} service={service} branch={branch} tp={10} object={object}/>
    );
};


