import React from "react";

export const PrivacyForm = (): JSX.Element => {
    return (
        <div className="hide-lsee-md hide-lsee-lg">
            Я подтверждаю, что ознакомлен и даю согласие на обработку персональных данных на
            условиях и для целей, определяемых <a href="/privacy" target={'/privacy'}>Политикой
            конфиденциальности</a>
        </div>

    );
}
