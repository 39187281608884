export function validURL(str:string) {
  let url;

  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}
export function resizeSrc(src:string, w:number, h:number) {
  return src.replace("/media/", `/media/resize/${w}/${h}/`);
}
export function cropSrc(src:string, w:number, h:number) {
  return src.replace("/media/", `/media/crop/${w}/${h}/`);
}