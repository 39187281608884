import * as React from 'react';
import {useParams} from "react-router";
import clsx from "clsx";
import {Meta} from "../base/Meta";
import {PageNotFound} from "../404/404";
import {cropSrc, resizeSrc} from "../../utils/utils";
import {useGetAlbumsByIdQuery} from "../../services/gallery";
import {Link} from "react-router-dom";
import {BreadCrumbsBlock} from "../simple/BreadCrumbsBlock";
import FsLightbox from "fslightbox-react";
import {useState} from "react";
import {Loader} from "../loader/Loader";

export const PhotoItem = (props: { item: ItemPhoto, album: ItemAlbum, onClick: React.MouseEventHandler }): JSX.Element => {
    const {item, album, onClick} = props;
    let src_image = resizeSrc(item.image, 250, 250)
    if (item.tp){
        if (item.tp=='2wh' || item.tp=='2h' || item.tp=='2w'){
            src_image = resizeSrc(item.image, 500, 500)
        }
    }

    return (
        <div className={`photoalbum__gallery-item photoalbum__gallery-item--${item.tp}`} onClick={onClick}>
            <div className="photoalbum__gallery-img-wrapper">
                <img src={src_image} alt={item.name}/>
            </div>
            <div className="photoalbum__gallery-info">
                <div className="photoalbum__gallery-img-wrapper">
                    <img src={src_image} alt={item.name}/>
                </div>
                <div className="photoalbum__gallery-text">
                    <a href={album.slug}>{album.name}</a>
                    <p>{item.name}</p>
                </div>
            </div>
        </div>
    )
};

const GalleryItem = (): JSX.Element => {
    let {slug} = useParams();
    const {data: album, isSuccess, isLoading, isFetching, isError} = useGetAlbumsByIdQuery({
        slug: slug
    });
    const [toggler, setToggler] = useState(false);
    const [slide, setSlide] = useState(1);
    if (isError) {
        return <PageNotFound/>
    }
    return (
        <React.Fragment>
            <div className="container">
                {
                    (isSuccess) ?
                        <FsLightbox toggler={toggler} slide={slide}
                                    sources={[...album.photos.map((photoItem) => photoItem.image)]} type={'image'}/>
                        : null
                }
                <div className={clsx("wrapper animate animateY", !isFetching && 'animated')}>
                    {
                        (isFetching) ? <Loader/> :
                            <div className="photoalbum__section">
                                <Meta title={album.name} {...album.metatags} />
                                <BreadCrumbsBlock items={[
                                    {name: 'Фотогалерея', link: '/gallery'},
                                    {name: album.name, link: album.slug}
                                ]}/>
                                <div className="photoalbum__top">
                                    <h1 className="photoalbum__title">{album.name}</h1>
                                    <div className="photoalbum__slides-count">
                                        <span>Всего {album.c_photos}</span>
                                        <img src="/assets/images/gallery-count-image.png" alt=""/>
                                    </div>
                                </div>
                                <div dangerouslySetInnerHTML={{__html: album.content}}/>

                                <div className="photoalbum__gallery">
                                    {album.photos.map((photoItem, index) => {
                                        return (
                                            <React.Fragment key={photoItem.id}>
                                                <PhotoItem item={photoItem} album={album} onClick={(e) => {
                                                    e.preventDefault();
                                                    setSlide(index + 1);
                                                    setToggler(!toggler);
                                                }}/>
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                                <div className={'more-block mt-20'}>
                                    <Link className='photoalbum__load-more' to={'/gallery'}>Все альбомы</Link>
                                </div>
                            </div>
                    }

                </div>
            </div>
        </React.Fragment>
    );
};

export default GalleryItem;

