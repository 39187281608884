import * as React from 'react';
import {useEffect, useState} from 'react';
import {defCoordMap, defCoordMapStr, InitialBranchCity, useGetBranchQuery} from "../../../services/cities";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {mainCity} from "../../dialog/components/cityReducer";
import clsx from "clsx";
import {YandexMap} from "../../base/Map";
import {Link} from "react-router-dom";
import {useAppInview} from "../../../hooks/useAppInView";

interface MapBlockProp {
    title: string,
    branch: number,
    city: number
}

export const MapBlock = (props: MapBlockProp): JSX.Element => {
    const {title, branch, city} = props
    const [BranchList, setList] = useState([]);
    const [PointsList, setPointsList] = useState([]);
    const [selectBranch, setSelectBranch] = useState<BranchCity>({...InitialBranchCity, ...{id: branch}});
    const {data: objectsBranch, isLoading} = useGetBranchQuery({city: city});
    useEffect(() => {
        if (objectsBranch) {
            setList([...objectsBranch]);
            const selected = objectsBranch.filter(item => (item.id === branch))[0]
            setSelectBranch(selected)
        }
    }, [branch, objectsBranch]);

    useEffect(() => {
        if (objectsBranch) {
            let points_list: any[] = []
            objectsBranch.map((item) => {
                points_list.push({
                    coords: item.map.split(',')
                })
            })
            setPointsList(points_list)
        }
    }, [objectsBranch]);

    const countBranch = BranchList.length;
    const cityMain = useAppSelector(mainCity);
    let center = defCoordMapStr.split(',');
    if (selectBranch && selectBranch.map){
        center = selectBranch.map.split(',')
    }
    let mapState = {
        center: center,
        zoom: 15,
    }
    const { ref, inView } = useAppInview();
    return (
        <>
            <div ref={ref} className={clsx("headcontact animate animateY", inView && 'animated')}>
                <div id="map">
                    <YandexMap key={(selectBranch) ? selectBranch.id : 'map'} state={mapState} points={PointsList}/>
                </div>
                <div className="wrapper">
                    <div className="headcontact__box">
                        <h2>{title}</h2>

                        <div className="headcontact__title">{countBranch} филиал{
                            (countBranch < 5 && countBranch > 1) ?
                                'а' : <React.Fragment>{(countBranch >= 5) ? 'ов' : ''}</React.Fragment>
                        } в городе {cityMain.name}:
                        </div>

                        <ul>
                            {BranchList.map((branch: BranchCity) => {
                                return (
                                    <li key={branch.id}>
                                        <a className={clsx(selectBranch && branch.id == selectBranch.id && "active")} href="#"
                                           onClick={(e) => {
                                               e.preventDefault();
                                               setSelectBranch(branch);
                                           }}>{(branch.address) ? branch.address : branch.name}</a>
                                    </li>
                                )
                            })}
                        </ul>

                        <Link to="/contacts" className="more">Смотреть все центры</Link>
                    </div>
                </div>
            </div>
        </>


    );
};
MapBlock.defaultProps = {
    title: 'Контакты центра «TopKids»',
}

