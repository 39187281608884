import * as React from 'react';
import {useEffect, useState} from 'react';
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {useGetServiceAgeQuery} from "../../../services/services";
import {useBranchContext} from "../../../context/BranchContext";
import SelectBranch from "../../base/form/SelectBranch";
import clsx from "clsx";
import {FormFio} from "../../base/form/FormFio";
import AnimateHeight from "react-animate-height";
import {toggleServiceDialog2} from "../dialogSlice";
import {FormFioProp} from "../../base/form/FormFioSlice";


export const ServiceItem = (props: { item: ItemService, selectServices: any[], onClick: any }): JSX.Element => {
    const item = props.item;
    const [show, setShow] = useState(false);
    const [height, setHeight] = useState<any>(0);
    const handleClick = () => {
        setHeight(height === 0 ? "auto" : 0);
        if (!show) {
            props.onClick(false)
        }
        setShow(!show)
    };
    const countCosts = item.costs.length
    return (
        <label key={item.id} className="modal__choose-drop">
            <div
                className={clsx("jq-checkbox", props.selectServices.indexOf(item.id) > -1 && "checked")}
                onClick={props.onClick}
            />
            <div
                aria-expanded={height !== 0}
                aria-controls={`service-panel-${item.id}`}
                className={clsx("modal__choose-title", show && "active")}
                onClick={handleClick}
            >{item.name}</div>
            <div>
                <AnimateHeight
                    id={`service-panel-${item.id}`}
                    duration={500}
                    height={height}

                >
                    <div className={clsx("modal__choose-block", show && "active")}>
                        <p>{item.text}</p>
                        {item.costs.map((obj) => {
                            return (
                                <dl className="modal-choose__info" key={obj.id}>
                                    {
                                        (countCosts > 1) ?
                                            <div className="modal-choose__info-item modal-choose__info-item-title">
                                                <dt>{obj.tp.display}</dt>
                                            </div> : null
                                    }

                                    <div className="modal-choose__info-item">
                                        <dt>Стоимость, месяца</dt>
                                        <dd>{obj.cost_month} ₽</dd>
                                    </div>
                                    <div className="modal-choose__info-item">
                                        <dt>Стоимость, занятия</dt>
                                        <dd>{obj.cost} ₽</dd>
                                    </div>
                                    <div className="modal-choose__info-item">
                                        <dt>Кол-во занятий</dt>
                                        <dd>{obj.number_lesson}</dd>
                                    </div>
                                </dl>
                            );
                        })}
                    </div>

                </AnimateHeight>
            </div>

        </label>
    )
};
interface ServiceDialogProp {
    pid: number
    params: FormFioProp
}
export const ServiceDialog = (props: ServiceDialogProp): JSX.Element => {
    const dispatch = useAppDispatch();
    const {branch, city} = useBranchContext();
    const [ageId, setAgeId] = useState(props.pid);
    const [openForm2, setOpenForm2] = useState({tp: 10, open: false});
    const [services, setServices] = useState([]);
    let serviceIds;
    if (props.params){
        serviceIds = props.params.service
    }
    const [selectServices, setselectServices] = useState(serviceIds || []);

    const [ageList, setList] = useState([]);
    const {data: object, isLoading, isSuccess, isFetching, isError} = useGetServiceAgeQuery({
        branch: branch
    });

    useEffect(() => {
        if (isSuccess) {
            setList([...object]);
        }
    }, [object]);

    useEffect(() => {
        let agesId = ageList.filter(item => (item.id === ageId))
        if (agesId.length) {
            setServices(agesId[0].services)
        } else {
            if (ageList.length) {
                setAgeId(ageList[0].id)
            }
        }
    }, [ageList, ageId]);

    const handleClickAge = (ageItem: ItemAge) => {
        setAgeId(ageItem.id)
        setselectServices([])
    };
    const handleClickServices = (service: number, flag?: boolean) => {
        if (selectServices.indexOf(service) > -1) {
            if (flag != false) {
                setselectServices(selectServices.filter(item => item !== service))
            }
        } else {
            setselectServices([...selectServices, service])
        }
    };

    return (
        <div>
            <h2>Подберите занятие для вашего ребёнка</h2>
            <SelectBranch/>
            <div className="modal__choose flex">
                <div className="modal__choose-sidebar flex space-between">
                    <div className="modal__choose-box">
                        <h3>Выберите возраст</h3>
                        {ageList.map((ageItem) => {
                            return (
                                <label key={ageItem.id} onClick={() => handleClickAge(ageItem)}>
                                    <div className={clsx("jq-radio", ageItem.id === ageId && "checked")}/>
                                    <span>{ageItem.name}</span>
                                </label>
                            );
                        })}
                    </div>

                    <div className="modal__choose-img">
                        <img src="/assets/images/modal-choose-img.jpg" alt=""/>
                    </div>
                </div>

                <div className="modal__choose-content flex space-between">
                    <div className="modal__choose-box">
                        <h3>Выберите необходимые услуги</h3>
                        <div className="modal__choose-head">и запишитесь на первое занятие или получите консультацию
                        </div>
                        {services.map((serviceItem) => {
                            return (
                                <ServiceItem key={serviceItem.id} item={serviceItem} selectServices={selectServices}
                                             onClick={(flag: boolean) => handleClickServices(serviceItem.id, flag)}/>
                            );
                        })}
                    </div>

                    <div className="modal__choose-btn flex space-between">
                        <a href="#" className="btn btn-yellow" onClick={(e) => {
                            e.preventDefault();
                            dispatch(toggleServiceDialog2({pid: ageId, isOpen:true, params: {age:ageId, service:selectServices, branch:branch,tp:openForm2.tp, object:object}}))
                        }}>Записаться на занятие</a>
                    </div>

                </div>
            </div>
        </div>
    );
};


