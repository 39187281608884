import React, {useRef} from "react";
import {Map, Placemark, RulerControl, YMaps} from "@pbe/react-yandex-maps";

interface YandexMapProp {
    state: any,
    points?: any[]
}

const PlacemarkOptions = {
        iconLayout: "default#image",
        iconImageHref: 'assets/images/marker.png',
        iconImageSize: [43, 59],
        iconContentOffset: [10, 8],
        iconImageOffset: [-39, -57],
        balloonShadow: false,
        balloonAutoPan: true,
        hideIconOnBalloonOpen: false
    }

export const YandexMap = (props: YandexMapProp): JSX.Element => {
    const mapState = props.state;
    const points = props.points || [];
    const mapRef = useRef(null)
    return (
        <React.Fragment>
            <YMaps>
                <Map
                    instanceRef={(ref) => ref && (mapRef.current = ref)}
                    width="100%"
                    height="100%"
                    defaultState={mapState}

                >
                    <RulerControl
                        onLoad={(e) => {
                            if (mapRef.current) {
                                mapRef.current.behaviors.disable('scrollZoom');
                            }
                        }}
                        // options={{float: "right"}}
                    />
                    {points.map((point, index) => (
                        <Placemark
                            key={index}
                            geometry={point.coords}
                            options={PlacemarkOptions}
                        />
                    ))}
                </Map>
            </YMaps>

        </React.Fragment>

    );
}
