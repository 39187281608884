import * as React from 'react';
import {useEffect, useState} from 'react';

import {useGetFaqQuery} from '../../../services/faq';
import AnimateHeight from "react-animate-height";
import clsx from "clsx";
import {useAppInview} from "../../../hooks/useAppInView";


export const FaqItem = (props: { item: ItemFaq }): JSX.Element => {
    const item = props.item;
    const [isOpen, setOpen] = useState(false);
    const [height, setHeight] = useState<any>(0);
    function toggleOpen() {
        setHeight(height === 0 ? "auto" : 0);
        setOpen(!isOpen);
    }
    const { ref, inView } = useAppInview();
    return (
        <div ref={ref} className={clsx("headanswer__box animate animateY", inView && 'animated')}>
            <div
                className={`headanswer-head ${(isOpen) ? 'active' : ''}`}
                aria-expanded={height !== 0}
                aria-controls={`headanswer-${item.id}`}
                onClick={toggleOpen}>{item.title}</div>
            <AnimateHeight
                    id={`headanswer-${item.id}`}
                    duration={500}
                    height={height}
                >
                <div className={`headanswer-block not_none`}
                     dangerouslySetInnerHTML={{__html: item.text}}/>
            </AnimateHeight>
        </div>
    )
};

export const FaqBlock = (props: BlockBranchProp): JSX.Element => {
    const {title, limit, city, branch} = props
    const [nextUrl, setNextUrl] = React.useState('');
    const [currentUrl, setCurrentUrl] = React.useState('');
    const [objectsList, setList] = useState([]);
    const {data: objects, isLoading, isSuccess, isFetching, isError} = useGetFaqQuery({
        limit: limit,
        offset: 0,
        city: city,
        branch: branch,
        url: currentUrl
    });

    useEffect(() => {
        setCurrentUrl('');
    }, [city]);

    useEffect(() => {
        if (objects) {
            if (currentUrl) {
                setList([...objectsList, ...objects.results]);
            } else {
                setList([...objects.results]);
            }
            setNextUrl(objects.next);
        }
    }, [objects,]);

    const { ref, inView } = useAppInview();
    return (
        <div className="headanswer">
            <div className="wrapper">
                <h2 ref={ref} className={clsx("animate animateY", inView && 'animated')}>{title}</h2>
                <div className="headanswer-boxes">
                    {objectsList.map((faqItem) => {
                        return (
                            <div key={faqItem.id} className='news-grid__content'>
                                <FaqItem item={faqItem}/>
                            </div>
                        );
                    })}
                </div>
                {nextUrl && (
                    <div className={'headanswer-more-block'}>
                        <button
                            className='headanswer-more'
                            onClick={() => setCurrentUrl(nextUrl)}
                        >
                            Показать еще
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};
FaqBlock.defaultProps = {
    title: 'Часто задаваемые вопросы',
    limit: 5,
}

