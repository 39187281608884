import * as React from 'react';
import {YandexMap} from "../base/Map";
import {mainBranch} from "../dialog/components/cityReducer";
import {useAppSelector} from "../../hooks/useAppSelector";
import {MetaPage} from "../base/MetaPage";
import SelectBranch from "../base/form/SelectBranch";
import {SocialBlock} from "../base/SocialBlock";
import {toggleConsDialog} from "../dialog/dialogSlice";
import {useAppDispatch} from "../../hooks/useAppDispatch";


const Contacts = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const branch_ = useAppSelector(mainBranch);
    let center = branch_.map.split(',')
    let points = []
    points.push({
        coords: center
    })
    let mapState = {
        center: center,
        zoom: 15,
    }
    const handleCons = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        dispatch(toggleConsDialog());
    };
    return (
        <div className="container">
            <div className="wrapper">
                <MetaPage/>
                <h1>Адреса и контакты</h1>
                <SelectBranch/>
                <div className="contact__box flex">
                    <div className="contact__box-info">
                        <div className="contact__box-title"><span>Адрес:</span> {branch_.address}
                        </div>
                        {
                            (branch_.phone) ? <div className="contact__box-title"><span>Телефон:</span> <a
                                href={`tel:${branch_.phone.replace(/\D/g, '')}`}>{branch_.phone}</a>
                            </div> : null
                        }
                        {
                            (branch_.email) ? <div className="contact__box-title"><span>Почта:</span> e-mail: <a
                                href={`mailto:${branch_.email}`}>{branch_.email}</a></div> : null
                        }

                        {
                            (Object.keys(branch_.social || {}).length) ?
                                <div className="contact__box-title">
                                    <span>Мы в соцсетях:</span>
                                    <SocialBlock branch={branch_}/>
                                </div> : null
                        }
                        <div className="contact__box-title">
                            <a href="#" onClick={handleCons} className="footer__write btn">Получить консультацию</a>
                        </div>
                        <img src="/assets/images/address-contact-img.jpg" alt="" className="contact__box-img"/>
                    </div>

                    <div className="contact__box-map">
                        <YandexMap key={branch_.id} state={mapState} points={points}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;
