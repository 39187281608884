import * as React from 'react';
import {useEffect, useState} from 'react';
import {useBranchContext} from "../../context/BranchContext";
import {useGetReviewsQuery} from "../../services/reviews";
import Moment from "moment";
import {useNavigate} from "react-router";
import {useLocation, useSearchParams} from "react-router-dom";
import {useGetPageByUrlQuery} from "../../services/flatepage";
import {Meta} from "../base/Meta";
import {toggleReviewDialog} from "../dialog/dialogSlice";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {cropSrc} from "../../utils/utils";
import {PageNotFound} from "../404/404";
import {PaginationBase} from "../base/PaginationBase";
import {Loader} from "../loader/Loader";
import {BreadCrumbsBlock} from "../simple/BreadCrumbsBlock";
import clsx from "clsx";


export const ReviewBlockListItem = (props: { item: ItemReview }): JSX.Element => {
    const item = props.item;
    const getLink = (item: ItemReview) => {
        return `/reviews/${item.id}`
    };
    const link = getLink(item)
    let src_image = "/assets/images/no-avatar.jpg"
    if (item.image) {
        src_image = cropSrc(item.image, 90, 90)
    } else {
        if (item.tp.value === 20) {
            src_image = "/assets/images/2gis-avatar.jpg"
        }
    }

    return (
        <div className="resporence__box flex align-item">
            <div className="resporence__box-avatar">
                <img src={src_image} alt={`Отзыв ${item.name}`}/>
            </div>

            <div className="resporence__box-info">
                <div className="resporence__name">{item.name}</div>
                <div className="resporence__data">{Moment(props.item.created).format('DD MMMM YYYY')}</div>
                <p className={'pre-wrap'}>{item.text}</p>

                <div className="resporence__bottom flex space-between">
                    <div className="resporence__address">
                        {
                            (item.address) ? <React.Fragment>Адрес: {item.address}</React.Fragment>
                                : null
                        }
                    </div>
                    {
                        (item.link) ? <div className="resporence__url">
                            <a href={item.link} target={"_blank"}>Прочитать отзыв на 2ГИС</a>
                        </div> : null
                    }
                </div>
            </div>
        </div>

    )
};

const ReviewList = (props: PageListProp): JSX.Element => {
    const dispatch = useAppDispatch();
    let {title, limit, metatags} = props
    const {pathname} = useLocation();
    const {data: simplePage, isSuccess} = useGetPageByUrlQuery({url: pathname});
    if (isSuccess) {
        title = simplePage.name
        metatags = simplePage.metatags
    }

    const [searchParams] = useSearchParams();
    let page = 1;
    if (searchParams.get('page')) {
        page = Number(searchParams.get('page'))
    }

    const navigate = useNavigate();
    const {branch, city} = useBranchContext();
    const [currentPage, setCurrentPage] = React.useState(page);
    const [counts, setCount] = React.useState(0);
    const [offset, setOffset] = React.useState((page - 1) * limit);
    const [currentUrl, setCurrentUrl] = React.useState('');
    const [reviewsList, setReviewsList] = useState([]);
    const {data: reviews, isFetching, isLoading} = useGetReviewsQuery({
        limit: limit,
        offset: offset,
        // branch: branch,
        city: city,
        url: currentUrl
    });

    useEffect(() => {
        let nPage = counts / limit;
        if (counts % limit > 0) {
            nPage = nPage + 1
        }
        if (page <= nPage && page > 0) {
            setOffset((page - 1) * limit);
            setCurrentPage(page)
        }
    }, [page]);

    useEffect(() => {
        setCurrentUrl('');
    }, [branch]);

    useEffect(() => {
        if (reviews) {
            setReviewsList([...reviews.results]);
            setCount(reviews.count);
        }
    }, [reviews]);

    function handlePageChange(page: number) {
        setCurrentPage(page)
        navigate(`/reviews?page=${page}`);
        window.scrollTo({left: 0, top: 0});
    }

    const handleReview = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        dispatch(toggleReviewDialog());
    };

    if (reviews?.results.length === 0) {
        return <PageNotFound/>
    }

    return (
        <React.Fragment>
            <Meta {...metatags} />
            <div className="container">
                <div className={clsx("wrapper animate animateY", !isFetching && 'animated')}>
                    {
                        (isFetching) ? <Loader/> :
                            <>
                                <BreadCrumbsBlock items={[{name: title, link: pathname}]}/>
                                <div className="container__header flex space-between align-item">
                                    <h1>{title}</h1>
                                    <a href="#" className="btn btn-yellow" onClick={handleReview}>Оставить отзыв</a>
                                </div>

                                <div className="resporence">
                                    {reviewsList.map((reviewItem) => {
                                        return (
                                            <div key={reviewItem.id}>
                                                <ReviewBlockListItem item={reviewItem}/>
                                            </div>
                                        );
                                    })}
                                </div>
                                <PaginationBase
                                    counts={counts}
                                    limit={limit}
                                    currentPage={currentPage}
                                    handlePageChange={handlePageChange}
                                    branch={branch}
                                    ttp={'reviews'}
                                />
                            </>
                    }
                </div>
            </div>
        </React.Fragment>
    );
};

export default ReviewList;

const title = 'Отзывы о детском центре «TopKids»'
ReviewList.defaultProps = {
    title: title,
    limit: 30,
    metatags: {
        title: title
    }
}
