import React, {useEffect, useState} from "react";
import Cookies from "js-cookie";
import {Link, useLocation} from "react-router-dom";

export const Cookie = (): JSX.Element => {
    const accept_val = Boolean(Cookies.get('cookielaw')) || false;

    const [accept, setAccept] = useState(accept_val);

    const location = useLocation();
    const {pathname} = location

    useEffect(() => {
        setShow(true)
    }, [pathname]);

    function acceptCookie() {
        Cookies.set('cookielaw', 'true')
        setAccept(true);
    }
    const [show, setShow] = useState(true);
    function toogleShow() {
        setShow(!show)
    }
    if (!accept && show) {
        return (
        <div className="cookie-popup" data-modal="cookie">
            <div className="wrapper cookie-popup__window" data-modal-window="true">
                <div className="cookie-popup__content">
                    <p className="cookie-popup__text">
                        Мы используем cookie-файлы, чтобы улучшить работу нашего сайта. Продолжая использовать этот
                        сайт,
                        Вы соглашаетесь с использованием cookie-файлов.
                    </p>
                    <div className="cookie-popup__buttons">
                        <button className="headlist-btn cookie-popup__accept" onClick={() => acceptCookie()}>Принять</button>
                        <Link className="cookie-popup__more" to="/cookie">Подробнее</Link>
                    </div>
                    <button className="cookie-popup__close" data-modal-cancel="true" onClick={() => toogleShow()}>
                        <img src="/assets/images/cookie-close.svg" alt=""/>
                    </button>
                </div>
            </div>
        </div>

    );
    }

}
