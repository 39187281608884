import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {mainBranch} from "../../dialog/components/cityReducer";
import {FormInput} from "./FormInput";
import {useForm} from "react-hook-form";
import {FormFioProp, resetForm, saveFormFio, selectFormFio} from "./FormFioSlice";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {FormThanks} from "./FormThanks";
import {PrivacyForm} from "./PrivacyForm";


export const FormCallBack = (props: FormFioProp): JSX.Element => {
    const dispatch = useAppDispatch();
    const [successSend, setSuccessSend] = useState(false);
    const branch = useAppSelector(mainBranch);
    const propsForm = {branch: props.branch, tp: props.tp}
    const {
        register,
        setError,
        handleSubmit,
        clearErrors,
        reset,
        control,
        formState: {errors}
    } = useForm<FormFioProp>(
        {defaultValues: {...propsForm,}}
    );
    useEffect(() => {
        setSuccessSend(false)
        return function cleanup() {
            dispatch(resetForm())
        };
    }, []);

    const form = useAppSelector(selectFormFio)
    const onSubmit = handleSubmit(data => {
        dispatch(saveFormFio(data));
    });

    useEffect(() => {
        clearErrors()
        if (form.error) {
            let err: any[] = []
            Object.entries(form.error)
                .map(([key, value]) => {
                    err.push(
                        {
                            type: "manual",
                            name: key,
                            message: value
                        }
                    )
                })
            err.forEach(({name, type, message}) =>
                setError(name, {type, message})
            );
        } else {
            clearErrors()
        }


    }, [form.error]);

    useEffect(() => {
        if (
            form.data.id && !successSend
        ) {
            setSuccessSend(true)
        }
    }, [form.data]);

    return (
        <React.Fragment>
            {
                (successSend) ?
                    <FormThanks name={form.data.name}/> :
                    <React.Fragment>
                        {props.children}
                        <div className="modal__callback-form">
                            <div className="modal__callback-head">Заполните форму</div>
                            <form className="sectionForm" onSubmit={onSubmit}>
                                <FormInput name={'name'} errors={errors} register={register} placeholder="Ваше имя"/>
                                <FormInput name={'phone'} errors={errors} register={register}
                                           placeholder="Ваш телефон" mask="+7 999 999 99 99"
                                           control={control}
                                />
                                <FormInput name={'email'} errors={errors} register={register} placeholder="Ваш email"/>

                                <button className="btn btn-yellow" type="submit">{props.buttonName || 'Отправить'}</button>

                                <div className="modal__police"><PrivacyForm/></div>
                            </form>
                        </div>
                    </React.Fragment>
            }
        </React.Fragment>


    );
}
