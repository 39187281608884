import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../../config/store';
import {serverAPIUrl} from "../../../services/api";


export interface FormFioProp {
    id?: number
    branch: number
    name?: string
    phone?: string
    email?: string
    age?: number
    service?: number[],
    tp?: number,
    children?: any,
    object?: ItemAge[],
    buttonName?: string
}

export const saveFormFio = createAsyncThunk(
    'service/order',
    async (data: FormFioProp, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        try {
            const response = await fetch(`${serverAPIUrl}service/order/`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            })

            const responseData = await response.json();

            if (response.status != 201) {
                return thunkAPI.rejectWithValue(responseData)
            }else{
                return thunkAPI.fulfillWithValue(responseData)
            }
        } catch (err) {
            return thunkAPI.rejectWithValue({serverError: err.toString()})
        }
    }
)
interface InitialState {
  data: any;
  error: any
}

const initialState: InitialState = {
    data: {
        id: null,
        name: '',
    },
    error: [],
}

export const formFioSlice = createSlice({
    name: 'formfio',
    initialState: initialState,
    reducers: {
        resetForm: (state: any) => {
          state.data = initialState;
          state.error = []
        },
    },
    extraReducers: (builder) => {
        builder.addCase(saveFormFio.fulfilled, (state: any, action: any) => {
            state.data = action.payload;
            state.error = []
        }).addCase(saveFormFio.rejected, (state: any, action: any) => {
            state.error = action.payload;
        })
    },
});

export const {
    resetForm
} = formFioSlice.actions;

export const selectFormFio = (state: RootState) => state.formfio;

export default formFioSlice.reducer;