import * as React from 'react';
import {useBranchContext} from "../../../context/BranchContext";
import {FormCallBack} from "../../base/form/FormCallBack";


export const ExcursionDialog = (): JSX.Element => {
    const {branch, city} = useBranchContext();
    return (
        <div className="modal__callback flex">
            <FormCallBack branch={branch} tp={40} buttonName={'Посетить экскурсию'} children={
                <div className="modal__callback-box">
                    <h2>Приходите <span>на экскурсию</span> по детскому саду</h2>
                    
                    <div className="modal__callback-title">и посмотрите своими глазами</div>

                    <img src="/assets/images/modal-image2.jpg" alt="" className="modal__callback-img hide-lsee-md hide-lsee-lg"/>
                </div>
            }/>
        </div>
    );
};


