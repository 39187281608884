import {Action, ThunkAction, configureStore} from '@reduxjs/toolkit';

import dialogReducer from '../features/dialog/dialogSlice';
import cityReducer from "../features/dialog/components/cityReducer";
import menuReducer from "../features/home/components/headerSlice";
import formFioReducer from "../features/base/form/FormFioSlice";
import formReviewReducer from "../features/base/form/FormReviewSlice";
import formMailReducer from "../features/base/form/FormMailSlice";
import formResumeReducer from "../features/base/form/FormResumeSlice";

import { api } from '../services/api'

// const initialState = window.INITIAL_STATE;

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        dialog: dialogReducer,
        city: cityReducer,
        menu: menuReducer,
        formfio: formFioReducer,
        formreview: formReviewReducer,
        formmail: formMailReducer,
        formresume: formResumeReducer,
    },
    // preloadedState: initialState
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
