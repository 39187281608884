import * as React from 'react';
import {useEffect} from 'react';
import {NewsBlock} from './components/NewsBlock';
import {ReviewBlock} from "./components/ReviewBlock";
import {PhotoBlock} from "./components/PhotoBlock";
import {WhyWe} from "./components/WhyWe";
import {FaqBlock} from "./components/FaqBlock";
import {HeaderBlock} from "./components/HeadBlock";
import {ServiceBlock} from "./components/ServiceBlock";
import {useBranchContext} from "../../context/BranchContext";
import {MapBlock} from "./components/MapBlock";
import {MetaPage} from "../base/MetaPage";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {setBranch, setCity} from "../dialog/components/cityReducer";
import {useGetAllBranchQuery, useGetBranchBySlugQuery} from "../../services/cities";
import {useParams} from "react-router";
import {PageNotFound} from "../404/404";

const Branch = (): JSX.Element => {
    const dispatch = useAppDispatch();
    let {slugCity, slugBranch} = useParams();
    const {data: blBranch, isSuccess, isLoading, isFetching, isError} = useGetBranchBySlugQuery({
        slug:slugBranch
    });
    const {branch, city} = useBranchContext();

    useEffect(() => {
        if (isSuccess){
            // @ts-ignore
            dispatch(setCity(blBranch.city))
            // @ts-ignore
            dispatch(setBranch({...blBranch, city: blBranch.city}))
        }

    }, [blBranch]);
    if (isError) {
        return <PageNotFound/>
    }
    return (
        <React.Fragment>
            <MetaPage/>
            <HeaderBlock/>
            <ServiceBlock branch={branch} city={city}/>
            <NewsBlock branch={branch} city={city}/>
            <PhotoBlock branch={branch} city={city}/>
            <WhyWe/>
            <FaqBlock branch={branch} city={city}/>
            <ReviewBlock branch={branch} city={city}/>
            <MapBlock branch={branch} city={city}/>
        </React.Fragment>
    );
};

export default Branch;
