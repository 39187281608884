import {api} from './api';

interface QueryAliasArg {
  alias: string
}
export const postsApi = api.injectEndpoints({
    endpoints: build => ({
        getMenuByAlias: build.query<ItemMenu, void | QueryAliasArg>({
            query: ({alias}:QueryAliasArg) => `/core/menu/${alias}/`,
            providesTags: data =>
                data
                    ? [
                        ...data.children.map(({id}) => ({
                            type: 'Core' as const,
                            id,
                        })),
                        {type: 'Core', id: `MENU-LIST-${data.id}`},
                    ]
                    : [...[], {type: 'Core', id: 'MENU-LIST'}],
        }),
    }),
});

export const {useGetMenuByAliasQuery} = postsApi;

export const {
    endpoints: {getMenuByAlias},
} = postsApi;
