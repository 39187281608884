import * as React from 'react';
import {useEffect, useState} from 'react';
import {useGetMenuByAliasQuery} from "../../../services/menus";
import {LinkOrUrl} from "../../base/LinkOrURL";
import {Link} from "react-router-dom";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {mainBranch} from "../../dialog/components/cityReducer";
import ScrollToTop from "react-scroll-to-top";
import {toggleCallBackDialog, toggleMailDialog} from "../../dialog/dialogSlice";
import {useAppDispatch} from "../../../hooks/useAppDispatch";


export const Footer = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const currentYear = (new Date().getFullYear())
    const yearTxt = currentYear === 2005 ? "2005" : "2005 - " + currentYear
    const [menuList, setMenuList] = useState([]);
    const {data: menus, isSuccess} = useGetMenuByAliasQuery({
        alias: 'footer'
    });

    useEffect(() => {
        if (isSuccess) {
            setMenuList([...menus.children]);
        }
    }, [menus]);

    const branch = useAppSelector(mainBranch);

    const handleMail = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        dispatch(toggleMailDialog());
    };
    const handleCallBack = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        dispatch(toggleCallBackDialog());
    };

    return (
        <React.Fragment>
            <footer className="footer inner">
                <div className="wrapper">
                    <div className="flex footer__flex space-between">
                        <div className="footer__block hide-lsee-lg">
                            <div className="footer__copyright">
                                {yearTxt} © Сеть детских развивающих центров
                                «TopKids»
                            </div>
                            <Link className="footer__police" to={'/privacy'}>Политика конфиденциальности</Link>
                            <div><a href="#" className="footer__write btn" onClick={handleMail}>Напишите
                                нам</a></div>
                        </div>

                        <div className="footer__block flex">

                            {menuList.map((item: ItemMenu) => {
                                return (
                                    <div className="footer__info" key={item.id}>
                                        <h4>{item.title}</h4>
                                        <ul>
                                            {item.children.map((subItem: ItemMenu) => {
                                                return (
                                                    <li key={subItem.id}>
                                                        <LinkOrUrl url={subItem.url} title={subItem.title}/>
                                                    </li>
                                                )
                                            })
                                            }
                                        </ul>
                                    </div>
                                )
                            })
                            }
                        </div>

                        <div className="footer__block">
                            <div className="footer__phone">
                                <div className="footer__phone-number nowrap"><a href="tel:">{branch.phone}</a></div>
                                <a href="#" onClick={handleCallBack} className="footer__phone-callback">Заказать
                                    звонок</a>
                            </div>
                            {
                                (Object.keys(branch.social || {}).length) ?
                                    <div className="footer__social">
                                        <div className="footer__social-title">Мы в социальных сетях</div>
                                        {Object.entries(branch.social)
                                            .map(([key, value]) => {
                                                if (value) {
                                                    return (<a key={key} target="_blank" href={value}>
                                                        <img className={key} src={`/assets/images/${key}-icon.svg`} alt={key}/></a>)
                                                }
                                            })
                                        }
                                    </div> : null
                            }

                        </div>
                    </div>
                    <ScrollToTop
                        smooth
                        className={"up-btn active"}
                        viewBox="0 0 24 24"
                        svgPath=""
                    />
                </div>
            </footer>
        </React.Fragment>
    );
};

