import React from "react";

export interface InputFileProps {
    name: string,
    errors: any,
    register: any,
    getValues?:any,
    control?: any
    className?: string,
    placeholder?: string,
    prefix?:string
}

export const FormFileInput2 = (props: InputFileProps): JSX.Element => {

    return (
        <React.Fragment>
            <label
                className={props.errors[props.name] ? "leave-comment__photo leave-comment__photo-error" : "leave-comment__photo"}>
                {props.errors[props.name]?.type === 'manual' &&
                    <label className="help-error">{props.errors[props.name]?.message}</label>}
                <div className={'form-block'}>
                    <input type="file"
                           {...props.register(props.name)}
                           className={props.className}
                           aria-invalid={props.errors[props.name] ? "true" : "false"}
                    />
                </div>
                <span>Прикрепить фотографию</span>
            </label>
        </React.Fragment>

    );
}

export const FormFileInput = (props: InputFileProps): JSX.Element => {
    const placeholder = (props.placeholder) ? props.placeholder : 'Прикрепить фотографию'
    const [file, setFile] = React.useState(placeholder);
    const handleChange = (e:any) => {
       if (e.target.files && e.target.files[0]){
           setFile(e.target.files[0].name)
       }
    }
    if (props.getValues(props.name)=='' && placeholder!=file){
        setFile(placeholder)
    }

    return (
        <React.Fragment>
            <div className={'form-block form-block-file'}>
                {props.errors[props.name]?.type === 'manual' &&
                    <label className="help-error">{props.errors[props.name]?.message}</label>}
                <input type="file" id={`file-${props.name}-${props.prefix}`}
                       {...props.register(props.name)}
                       className={(props.className) ? props.className : 'inputfile'}
                       aria-invalid={props.errors[props.name] ? "true" : "false"}
                       onChange={handleChange}
                />
                <label htmlFor={`file-${props.name}-${props.prefix}`} className={props.errors[props.name] ? "inputfile-error" : null}>
                    {file}
                </label>
            </div>

        </React.Fragment>

    );
}
