import * as React from 'react';
import {NewsBlock} from './components/NewsBlock';
import {ReviewBlock} from "./components/ReviewBlock";
import {PhotoBlock} from "./components/PhotoBlock";
import {WhyWe} from "./components/WhyWe";
import {FaqBlock} from "./components/FaqBlock";
import {HeaderBlock} from "./components/HeadBlock";
import {ServiceBlock} from "./components/ServiceBlock";
import {useBranchContext} from "../../context/BranchContext";
import {MapBlock} from "./components/MapBlock";
import {MetaPage} from "../base/MetaPage";

const Home = (): JSX.Element => {
    const {branch, city} = useBranchContext();
    return (
        <React.Fragment>
            <MetaPage/>
            <HeaderBlock/>
            <ServiceBlock city={city}/>
            <NewsBlock city={city}/>
            <PhotoBlock city={city}/>
            <WhyWe/>
            <FaqBlock city={city}/>
            <ReviewBlock city={city}/>
            <MapBlock branch={branch} city={city}/>
        </React.Fragment>
    );
};

export default Home;
