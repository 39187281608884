import React from "react";
import InputMask from "react-input-mask";

export interface InputProps {
    name: string,
    placeholder: string,
    errors: any,
    register: any,
    mask?: string,
    control?: any
    rows?: number,
    className?: string,
    prefix?:string
}

export const FormInput = (props: InputProps): JSX.Element => {

    return (
        <div className={'form-block'}>
            {props.errors[props.name]?.type === 'manual' &&
                <label className="help-error">{props.errors[props.name]?.message}</label>}
            {
                props.mask ?
                    <InputMask
                        mask={props.mask}
                        alwaysShowMask={false}
                        type={'text'}
                        className={props.className}
                        placeholder={props.placeholder}
                        {...props.register(props.name)}
                        aria-invalid={props.errors[props.name] ? "true" : "false"}
                    /> :
                    <input type="text" placeholder={props.placeholder} autoComplete="off"
                           {...props.register(props.name)}
                           className={props.className}
                           aria-invalid={props.errors[props.name] ? "true" : "false"}
                    />
            }
        </div>

    );
}


