import * as React from 'react';
import {useEffect, useState} from 'react';
import {useBranchContext} from "../../context/BranchContext";
import {useGetEmployeesFullQuery} from "../../services/employer";
import clsx from "clsx";
import FsLightbox from "fslightbox-react";
import SelectBranch from "../base/form/SelectBranch";
import {SimpleBlock} from "../simple/SimpleBlock";
import {cropSrc} from "../../utils/utils";
import AnimateHeight from "react-animate-height";
import {Loader} from "../loader/Loader";

export const CertificateEmployee = (props: { item: ItemCertificate, onClick: React.MouseEventHandler }): JSX.Element => {
    const item = props.item;
    const src_image = cropSrc(item.image, 145, 160)
    return (
        <a href={item.image || '#'} onClick={props.onClick}>
            <img src={src_image} alt={item.name}/>
        </a>
    )
};

export const EmployeeItem = (props: { item: ItemEmployee }): JSX.Element => {
    const item = props.item;
    const [isOpen, setOpen] = useState(false);
    const [height, setHeight] = useState<any>(0);

    function toggleOpen(e: React.MouseEvent<HTMLElement>) {
        e.preventDefault();
        setHeight(height === 0 ? "auto" : 0);
        setOpen(!isOpen);
    }

    let src_image = "/assets/images/no-avatar.jpg"
    if (item.image) {
        src_image = cropSrc(item.image, 90, 90)
    }
    const certificates = item.certificates;

    const [toggler, setToggler] = useState(false);
    const [slide, setSlide] = useState(1);

    return (
        <React.Fragment>
            <div className="personal__box-avatar">
                <img src={src_image} alt={`${item.name}, ${item.position}`}/>
            </div>

            <div className="personal__box-block">
                <div className="personal__box-header flex space-between">
                    <div className="personal__box-name">{item.name}, {item.position}</div>
                    <a href="#"
                    className={clsx("personal__box-more", isOpen && 'active')}
                    aria-expanded={height !== 0}
                    aria-controls={`price-group-${item.id}`}
                    onClick={(e) => {toggleOpen(e)}}
                    >Подробнее</a>
                </div>

                <div className="personal__box-info flex">
                    <div>Общий. стаж работы / пед. стаж: {item.experience}</div>
                    <div>Образование: {item.education}</div>
                </div>
                <FsLightbox toggler={toggler} slide={slide}
                            sources={[...certificates.map((item) => item.image)]} type={'image'}/>
                <AnimateHeight
                    id={`employee-${item.id}`}
                    duration={500}
                    height={height}
                >
                    <div className={'personal__box-drop'} dangerouslySetInnerHTML={{__html: item.content}}/>
                    <div className="personal__box-gallery flex">
                        {certificates.map((item, index) => {
                            return (
                                <div key={item.id}>
                                    <CertificateEmployee item={item} onClick={(e) => {
                                        e.preventDefault();
                                        setSlide(index + 1);
                                        setToggler(!toggler);
                                    }}/>
                                </div>
                            );
                        })}

                    </div>
                </AnimateHeight>
            </div>
        </React.Fragment>
    )
};

interface EmployeeBlockProp {
    title: string,
    limit: number,
}

export const EmployeeList = (props: EmployeeBlockProp): JSX.Element => {
    const {title, limit} = props
    const {branch, city} = useBranchContext();
    const [nextUrl, setNextUrl] = React.useState('');
    const [currentUrl, setCurrentUrl] = React.useState('');
    const [objectsList, setList] = useState([]);
    const {data: objects, isLoading, isSuccess, isFetching, isError} = useGetEmployeesFullQuery({
        limit: limit,
        offset: 0,
        branch: branch,
        url: currentUrl
    });

    useEffect(() => {
        setCurrentUrl('');
    }, [branch]);

    useEffect(() => {
        if (objects) {
            if (currentUrl) {
                setList([...objectsList, ...objects.results]);
            } else {
                setList([...objects.results]);
            }
            setNextUrl(objects.next);
        }
    }, [objects,]);
    return (
        <div className="container">
            <div className={clsx("wrapper animate animateY", !isFetching && 'animated')}>
                <SimpleBlock title={title}/>
                <SelectBranch/>
                {
                    (isFetching) ? <Loader/> :
                        <>
                            <div className="personal">
                                {objectsList.map((item) => {
                                    return (
                                        <div key={item.id} className='personal__box flex'>
                                            <EmployeeItem item={item}/>
                                        </div>
                                    );
                                })}
                            </div>
                            {nextUrl && (
                                <div className={'more-block mt-20'}>
                                    <button
                                        className='headanswer-more'
                                        onClick={() => setCurrentUrl(nextUrl)}
                                    >
                                        Показать еще
                                    </button>
                                </div>
                            )}
                        </>
                }
            </div>
        </div>
    );
};
EmployeeList.defaultProps = {
    title: 'Руководство и педагогический состав',
    limit: 15,
}

