import React from "react";
import {InputProps} from "./FormInput";

export const FormTextArea = (props: InputProps): JSX.Element => {

    return (
        <div className={'form-block'}>
            {props.errors[props.name]?.type === 'manual' &&
                <p className="help-error">{props.errors[props.name]?.message}</p>}

            <textarea placeholder={props.placeholder} autoComplete="off"
                      {...props.register(props.name)}
                      aria-invalid={props.errors[props.name] ? "true" : "false"}
                      rows={props.rows}
            />
        </div>

    );
}
