import React from "react";
import {OptionCity} from "../../dialog/components/CityDialog";
import CustomSelect from "./CustomSelect";
import {useBranchContext} from "../../../context/BranchContext";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {mainBranch, mainCity, setBranch} from "../../dialog/components/cityReducer";
import {useGetBranchQuery} from "../../../services/cities";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {CountBranch} from "../CountBranch";
import {toggleCityDialog, toggleDialog} from "../../dialog/dialogSlice";

const SelectBranch = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const {branch, city} = useBranchContext();
    const branch_ = useAppSelector(mainBranch);
    const {data: objectsBranch} = useGetBranchQuery({city: city});
    const city_ = useAppSelector(mainCity);

    function handlerSelectBranch(selectedOption: OptionCity | null) {
        dispatch(setBranch({...selectedOption, city: city_}))
    }
    const handleModalCity = () => {
        dispatch(toggleCityDialog({isOpen:true}));
    };

    return (
        <React.Fragment>
            <div className="header__city modal-choose__city">
                <div className="header__city-name">

                    {city_.name}
                    <span>{(city_.name) ? ',': ''}&nbsp;<CountBranch countBranch={city_.c_branch}/></span>
                </div>
                <span className="city-modal__label" onClick={() => handleModalCity()}>Сменить город</span>
            </div>
            <CustomSelect
                key={branch_.id + '-' + branch_.name}
                className={'react-select'}
                defaultValue={branch_}
                getOptionLabel={(item: OptionCity) => item.name}
                getOptionValue={(item: OptionCity) => item.id}
                options={objectsBranch}
                isClearable={false}
                placeholder={'Выберите филиал'}
                onChange={handlerSelectBranch}
            />
        </React.Fragment>

    );
}


export default SelectBranch;