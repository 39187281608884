import React from "react";

interface SocialBlockProp {
    branch: BranchCity,
}

export const SocialBlock = (props: SocialBlockProp): JSX.Element => {
    const branch = props.branch
    return (
        <React.Fragment>
            <ul className="bottom-menu__socials">
                {Object.entries(branch.social)
                    .map(([key, value]) => {
                        if (value) {
                            return (
                                <li key={key} className="bottom-menu__social">
                                    <a target="_blank" href={value}>
                                        <img src={`/assets/images/menu-${key}.svg`} alt={key}/>
                                    </a>
                                </li>)
                        }
                    })
                }
            </ul>
        </React.Fragment>

    );
}
