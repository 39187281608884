import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {FormFioProp, resetForm, saveFormFio, selectFormFio} from "./FormFioSlice";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {FormInput} from "./FormInput";
import {toggleDialogThank, toggleServiceDialog} from "../../dialog/dialogSlice";
import {PrivacyForm} from "./PrivacyForm";


export const FormFio = (props: FormFioProp): JSX.Element => {
    const dispatch = useAppDispatch();
    const [successSend, setSuccessSend] = useState(false);
    const {
        register,
        setValue,
        setError,
        handleSubmit,
        clearErrors,
        reset,
        control,
        formState: {errors}
    } = useForm<FormFioProp>(
        {defaultValues: {...props,}}
    );

    useEffect(() => {
        setSuccessSend(false)
        return function cleanup() {
            dispatch(resetForm())
        };
    }, []);

    const form = useAppSelector(selectFormFio)
    const onSubmit = handleSubmit(data => {
        dispatch(saveFormFio(data));
    });

    useEffect(() => {
        clearErrors()
        if (form.error) {
            let err: any[] = []
            Object.entries(form.error)
                .map(([key, value]) => {
                    err.push(
                        {
                            type: "manual",
                            name: key,
                            message: value
                        }
                    )
                })
            err.forEach(({name, type, message}) =>
                setError(name, {type, message})
            );
        } else {
            clearErrors()
        }


    }, [form.error]);

    useEffect(() => {
        if (
            form.data.id && !successSend
        ) {
            setSuccessSend(true)
            dispatch(toggleDialogThank({name: form.data.name, isOpen: true, component:'ServiceDialogThanks'}))
        }
    }, [form.data]);
    console.log(props)
    let ageId = props.age || 0;
    const ageList = props.object || [];
    const serviceIds = props.service || []
    let age;
    let services
    if (ageList) {
        const ages = ageList.filter(item => (item.id === ageId))
        if (ages) {
            age = ages[0]
            services = age.services.filter(item => (serviceIds.includes(item.id)))
        }
    }
    return (
        <React.Fragment>
            <button
                className="modal-fill-form__go-back"
                onClick={(e) => dispatch(toggleServiceDialog({
                    pid: ageId,
                    isOpen: true,
                    params: {age: ageId, service: serviceIds, branch: props.branch, tp: props.tp, object: ageList}
                }))}
            >
                <img src="./images/arrow-left.svg" alt=""/>
                <span>Вернуться к выбору услуг</span>
            </button>
            <div className={"modal-fill-form__wrapper"}>
                <div className="modal-fill-form__choice">
                    <div className="modal-fill-form__icon">
                        <img src="/assets/images/el_ok-circle.svg" alt=""/>
                    </div>
                    <div className="modal-fill-form__text-content">
                        <b>Вы выбрали: </b>
                        <span className="modal-fill-form__age-range">{age.name}</span>
                        <p>
                            {services.map((serviceItem, i, arr) => {
                                return (
                                    <span key={serviceItem.id}>{serviceItem.name}
                                        {
                                            (i + 1 === arr.length) ? null : ', '
                                        }
                                            </span>
                                );
                            })}
                        </p>
                    </div>
                </div>
                <form className={'modal-choose-forms bg modal-fill-form__form'} onSubmit={onSubmit}>
                    <h3 className="modal-fill-form__title">Заполните форму</h3>
                    <div className="modal-fill-form__elements">
                        <FormInput className={'w-100'} name={'name'} errors={errors} register={register}
                                   placeholder="Ваше имя"/>
                        <FormInput className={'w-100'} name={'phone'} errors={errors} register={register}
                                   placeholder="Ваш телефон" mask="+7 999 999 99 99"
                                   control={control}
                        />
                        <FormInput className={'w-100'} name={'email'} errors={errors} register={register}
                                   placeholder="Ваш email"/>
                    </div>
                    <button className="headlist-btn modal-fill-form__sbmt" type="submit">Отправить</button>
                    <p className="modal-fill-form__caption">
                        <PrivacyForm />
                    </p>
                </form>
            </div>
        </React.Fragment>


    );
}
