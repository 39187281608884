import * as React from 'react';
import {useAppDispatch} from '../../../hooks/useAppDispatch';
import {toggleDialog} from '../../dialog/dialogSlice';
import {mainBranch, mainCity} from "../../dialog/components/cityReducer";
import {useAppSelector} from "../../../hooks/useAppSelector";

export const SelectCityButton = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const handleModalCity = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        dispatch(toggleDialog({component: 'CityDialog', title: 'Выберите ближайший к Вам центр', id: 'city-modal modal-city'}));
    };
    const city = useAppSelector(mainCity);
    return (
        <div className="header__city">
            <div className="header__city-name">{city.name}</div>
            <a className="header__city-select" href="#" onClick={handleModalCity}>Выбрать город</a>
        </div>
    );
};

