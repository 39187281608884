import * as React from 'react';

import {Dialog, Transition} from '@headlessui/react'
import {paramsDialog, toggleDialog} from './dialogSlice';
import {useAppDispatch} from '../../hooks/useAppDispatch';
import {useAppSelector} from '../../hooks/useAppSelector';
import {CityDialog} from "./components/CityDialog";
import {PriceDialog} from "./components/PriceDialog";
import {TimeTableDialog} from "./components/TimeTableDialog";
import clsx from "clsx";
import {LessonDialog} from "./components/LessonDialog";
import {ServiceDialog} from "./components/ServiceDialog";
import {FreeLessonDialog} from "./components/FreeLessonDialog";
import {ExcursionDialog} from "./components/ExcursionDialog";
import {ConsDialog} from "./components/ConsDialog";
import {ReviewDialog} from "./components/ReviewDialog";
import {ServiceDialog2} from "./components/ServiceDialog2";
// import {FormThanks} from "../base/form/FormThanks";
import {DialogThanks} from "./components/DialogThanks";
import {ServiceDialogThanks} from "./components/ServiceDialogThanks";
import {ReviewDialogThanks} from "./components/ReviewDialogThanks";
import {MailDialog} from "./components/MailDialog";
import {VacancyDialog} from "./components/VacancyDialog";
import {CallBackDialog} from "./components/CallBackDialog";

const CustomDialog = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const {isOpen, title, component, id, pid, name, params} = useAppSelector(paramsDialog);
    return (
        <Transition
            as={React.Fragment}
            show={isOpen}
        >
            <Dialog className={clsx("fancybox-container", isOpen && "fancybox-is-open")} open={isOpen}
                    onClose={() => dispatch(toggleDialog())}>
                <Dialog.Panel className={"fancybox-inner"}>
                    <div className="fancybox-stage">
                        <div
                            className={"fancybox-slide fancybox-slide--inline fancybox-slide--current fancybox-slide--complete"}>
                            <div className="fancybox-bg" aria-hidden="true"
                                 onClick={() => dispatch(toggleDialog())}/>
                            <div className={`modal ${id}`}>
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(toggleDialog())
                                }} className="close"></a>
                                {(title) ? <Dialog.Title dangerouslySetInnerHTML={{__html: title}}/> : null}
                                {(component == 'CityDialog') ? <CityDialog/> : null}
                                {(component == 'PriceDialog') ? <PriceDialog tp={params}/> : null}
                                {(component == 'TimeTableDialog') ? <TimeTableDialog tp={params}/> : null}
                                {(component == 'LessonDialog') ? <LessonDialog id={params}/> : null}
                                {(component == 'ServiceDialog') ? <ServiceDialog pid={pid} params={params}/> : null}
                                {(component == 'ServiceDialog2') ? <ServiceDialog2 pid={pid} params={params}/> : null}
                                {(component == 'ServiceDialogThanks') ? <ServiceDialogThanks name={name}/> : null}
                                {/*{(component == 'FormThanks') ? <FormThanks name={name}/> : null}*/}
                                {(component == 'FreeLessonDialog') ? <FreeLessonDialog/> : null}
                                {(component == 'ExcursionDialog') ? <ExcursionDialog/> : null}
                                {(component == 'ConsDialog') ? <ConsDialog/> : null}
                                {(component == 'CallBackDialog') ? <CallBackDialog/> : null}
                                {(component == 'ReviewDialog') ? <ReviewDialog/> : null}
                                {(component == 'ReviewDialogThanks') ? <ReviewDialogThanks name={name} /> : null}
                                {(component == 'MailDialog') ? <MailDialog/> : null}
                                {(component == 'VacancyDialog') ? <VacancyDialog pid={pid} params={params}/> : null}
                                {(component == 'DialogThanks') ? <DialogThanks name={name} /> : null}
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>

        </Transition>
    )
}

export default CustomDialog;
