import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../../config/store';

interface InitialState {
    isOpen: boolean;
    isLetSee: boolean;
}

const initialState: InitialState = {
    isOpen: false,
    isLetSee: false,
};

export const headerSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        toggleMenu: (state) => {
            state.isOpen = !state.isOpen;
        },
        toggleLetSee: (state) => {
            state.isLetSee = !state.isLetSee;
        },
    },
});

export const {toggleMenu, toggleLetSee} = headerSlice.actions;

export const isOpen = (state: RootState) => state.menu.isOpen;
export const isLetSee = (state: RootState) => state.menu.isLetSee;
export const paramsMenu = (state: RootState) => state.menu;


export default headerSlice.reducer;
