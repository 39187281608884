import {createApi, fetchBaseQuery, retry} from '@reduxjs/toolkit/query/react'

export const serverAPIDomain = process.env['NODE_ENV'] === 'development' ? 'http://localhost:8015' : '';
export const serverAPIUrl = `${serverAPIDomain}/api/`

const baseQuery = fetchBaseQuery({
    baseUrl: serverAPIUrl,
})

const baseQueryWithRetry = retry(baseQuery, {maxRetries: 0})

// const createApi = buildCreateApi(
//   coreModule(),
//   reactHooksModule({ unstable__sideEffectsInRender: true })
// )

export const api = createApi({
    reducerPath: 'restApi',
    baseQuery: baseQueryWithRetry,
    tagTypes: ['Reviews', 'News', 'Gallery', 'Faq', 'Services', 'Core', 'Employees', 'Vacancy', 'Interior', 'Page'],
    endpoints: () => ({}),
    // extractRehydrationInfo(action, { reducerPath }) {
    //   console.log(action.type, '***************action', reducerPath, action)
    //   if (action.type === REHYDRATE) {
    //     return action.payload[reducerPath];
    //   }
    // },
})



