import * as React from 'react';
import {useEffect, useState} from 'react';
import {useBranchContext} from "../../context/BranchContext";
import {useNavigate} from "react-router";
import {useLocation, useSearchParams} from "react-router-dom";
import clsx from "clsx";
import {NewsBlockItem} from "./NewsBlockItem";
import {useGetNewsQuery} from "../../services/news";
import {useGetPageByUrlQuery} from "../../services/flatepage";
import {Meta} from "../base/Meta";
import {PageNotFound} from "../404/404";
import {Loader} from "../loader/Loader";
import {BreadCrumbsBlock} from "../simple/BreadCrumbsBlock";
import {PaginationBase} from "../base/PaginationBase";
import {ArticleBlockItem} from "./ArticleBlockItem";

interface NewsPageListProp extends PageListProp {
    ttp: string;
}

const NewsList = (props: NewsPageListProp): JSX.Element => {
    const [searchParams] = useSearchParams();
    let page = 1;
    if (searchParams.get('page')) {
        page = Number(searchParams.get('page'))
    }

    let {title, limit, metatags, ttp} = props
    if (ttp!='news'){
        title = 'Статьи'
    }
    const {pathname} = useLocation();
    const {data: simplePage, isSuccess} = useGetPageByUrlQuery({url: pathname});
    if (isSuccess) {
        title = simplePage.name
        metatags = simplePage.metatags
    }
    const navigate = useNavigate();
    const {branch, city} = useBranchContext();
    const [currentPage, setCurrentPage] = React.useState(page);
    const [counts, setCount] = React.useState(1);
    const [offset, setOffset] = React.useState((page - 1) * limit);
    const [articleList, setArticleList] = useState([]);
    const {data: articles, isLoading, isFetching, isSuccess: isSuccessNews} = useGetNewsQuery({
        limit: limit,
        offset: offset,
        city: city,
        ttp: ttp
    });

    useEffect(() => {
        let nPage = counts / limit;
        if (counts % limit > 0) {
            nPage = nPage + 1
        }
        if (page <= nPage && page > 0) {
            setOffset((page - 1) * limit);
            setCurrentPage(page)
        }
    }, [page]);

    useEffect(() => {
        if (articles) {
            setArticleList([...articles.results]);
            setCount(articles.count);
        }
    }, [articles]);

    function handlePageChange(page: number) {
        setCurrentPage(page)
        navigate(`/${props.ttp}?page=${page}`);
        window.scrollTo({left: 0, top: 0});
    }


    if (articles?.results.length === 0) {
        return <PageNotFound/>
    }
    return (
        <>
            <Meta {...metatags} />
            <div className="container">
                <div className={clsx("wrapper animate animateY", !isFetching && 'animated')}>
                    {
                        (isFetching) ? <Loader/> :
                            <>
                                <BreadCrumbsBlock items={[{name: title, link: pathname}]}/>
                                <div className="container__header flex space-between align-item">
                                    <h1>{title}</h1>
                                </div>
                                {
                                    (ttp == 'news') ?
                                        <div className="new-news-list__items">

                                            {articleList.map((articleItem) => {
                                                return (
                                                    <NewsBlockItem
                                                        key={articleItem.id}
                                                        className={clsx("headnews__block flex", isLoading && 'is-loading')}
                                                        item={articleItem} ttp={props.ttp}/>
                                                );
                                            })}

                                        </div> :
                                        <div className="resporence">
                                            {articleList.map((articleItem) => {
                                                return (
                                                    <div key={articleItem.id}>
                                                        <ArticleBlockItem
                                                            className={clsx("headnews__block flex", isLoading && 'is-loading')}
                                                            item={articleItem} ttp={props.ttp}/>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                }

                                <PaginationBase
                                    counts={counts}
                                    limit={limit}
                                    currentPage={currentPage}
                                    handlePageChange={handlePageChange}
                                    branch={branch}
                                    ttp={ttp}
                                />
                            </>
                    }

                </div>
            </div>
        </>
    );

};

export default NewsList;
const title = 'Новости и акции'
NewsList.defaultProps = {
    ttp: 'news',
    title: title,
    limit: 30,
    metatags: {
        title: title
    }
}
