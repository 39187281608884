import * as React from 'react';
import {SimpleBlock} from "../simple/SimpleBlock";
import {PriceBlock} from "./PriceBlock";

interface PriceProp {
    tp: number,
}

const Price = (props: PriceProp): JSX.Element => {
    return (
        <div className="container">
            <div className="wrapper">
                <SimpleBlock title={'Прайс на услуги'}/>
                <PriceBlock tp={props.tp} hide_h={true}/>
            </div>
        </div>
    );
};

export default Price;
