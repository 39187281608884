import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {FormInput} from "./FormInput";
import {FormResumeProp, resetResumeForm, saveFormResume, selectFormResume} from "./FormResumeSlice";
import {toggleDialogThank} from "../../dialog/dialogSlice";
import {PrivacyForm} from "./PrivacyForm";
import {FormFileInput} from "./FormFileInput";
import {mainBranch} from "../../dialog/components/cityReducer";


export const FormResume = (props: FormResumeProp): JSX.Element => {
    const dispatch = useAppDispatch();
    const branch = useAppSelector(mainBranch);
    const {prefix} = props;
    const [successSend, setSuccessSend] = useState(false);
    const {
        register,
        setError,
        handleSubmit,
        clearErrors,
        reset,
        control,
        getValues,
        formState: {errors}
    } = useForm<FormResumeProp>(
        {defaultValues: {...props, branch: branch.id}}
    );

    useEffect(() => {
        setSuccessSend(false);
        dispatch(resetResumeForm())
        return function cleanup() {
            dispatch(resetResumeForm())
        };
    }, []);

    const form = useAppSelector(selectFormResume)
    const onSubmit = handleSubmit(data => {
        dispatch(saveFormResume(data));
    });

    useEffect(() => {
        clearErrors()
        if (form.error) {
            let err: any[] = []
            Object.entries(form.error)
                .map(([key, value]) => {
                    err.push(
                        {
                            type: "manual",
                            name: key,
                            message: value
                        }
                    )
                })
            err.forEach(({name, type, message}) =>
                setError(name, {type, message})
            );
        }


    }, [form.error]);

    useEffect(() => {
        if (
            form.data.id && !successSend
        ) {
            reset();
            reset({'phone': '', 'portfolio': '', 'rezume': ''});
            dispatch(resetResumeForm())
            setSuccessSend(true)
            dispatch(toggleDialogThank({name: form.data.first_name, isOpen: true, id: null}))
        }
    }, [form.data]);
    return (
        <form
            id={prefix + '-prefix'}
            name={prefix + '-prefix'}
            onSubmit={onSubmit}
        >
            <div className="vakancy__form-flex flex">
                <FormInput prefix={prefix} name={'first_name'} errors={errors} register={register} placeholder="Имя"/>
                <FormInput prefix={prefix} name={'last_name'} errors={errors} register={register}
                           placeholder="Фамилия"/>
                <FormInput prefix={prefix} name={'patronymic'} errors={errors} register={register}
                           placeholder="Отчество"/>
                <FormInput prefix={prefix} name={'email'} errors={errors} register={register} placeholder="E-mail"/>
                <FormInput prefix={prefix} name={'phone'} errors={errors} register={register}
                           placeholder="Телефон" mask="+7 999 999 99 99"
                           control={control}
                />
                <FormInput name={'city_str'} errors={errors} register={register} placeholder="Город"/>
                <FormFileInput prefix={prefix} name={'rezume'} getValues={getValues} errors={errors} register={register}
                               placeholder="Прикрепить резюме"/>
                <FormFileInput prefix={prefix} name={'portfolio'} getValues={getValues} errors={errors}
                               register={register}
                               placeholder={'Прикрепить портфолио'}/>
                <div className="vakancy__form-col">
                    <button form={prefix + '-prefix'} className="btn btn-yellow" type="submit">Отправить данные</button>
                </div>
            </div>

            <div className="vakancy__form-police"><PrivacyForm/></div>
        </form>

    );
}


