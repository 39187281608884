import * as React from 'react';
import {useLocation} from "react-router-dom";
import {useGetPageByUrlQuery} from "../../services/flatepage";
import {PageNotFound} from "../404/404";
import {toggleDialog, toggleLessonDialog, togglePriceTableDialog, toggleTimeTableDialog} from "../dialog/dialogSlice";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {Meta} from "../base/Meta";
import {SliderSimplePage} from "./SliderSimplePage";
import clsx from "clsx";
import {Loader} from "../loader/Loader";
import $ from 'jquery';

export const SimplePage = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const {pathname} = location
    const {data: simplePageObject, isLoading, isSuccess, isFetching, isError} = useGetPageByUrlQuery({
        url: pathname,
        omit: 'slider'
    });

    const clickHandler = (e: React.MouseEvent<HTMLElement>) => {
        let el = e.target;
        const buttonMode = (e.target as HTMLButtonElement).getAttribute('data-type');
        if (buttonMode) {
            if (buttonMode == 'price') {
                dispatch(togglePriceTableDialog({tp: 20}));
            }
            if (buttonMode == 'price-sad') {
                dispatch(togglePriceTableDialog({tp: 10}));
            }
            if (buttonMode == 'timetable') {
                dispatch(toggleTimeTableDialog({tp: 20}))
            }
            if (buttonMode == 'timetable-sad') {
                dispatch(toggleTimeTableDialog({tp: 10}))
            }
            if (buttonMode == 'lesson') {
                const typeLesson = (e.target as HTMLButtonElement).getAttribute('data-lesson');
                dispatch(toggleLessonDialog({
                    tp: typeLesson
                }));
            }
        }
        // @ts-ignore
        if (el.className == 'headanswer-head') {
            // @ts-ignore
            // @ts-ignore
            const nextNode = el.nextElementSibling;
                if(!nextNode.classList.contains("active")){
                    nextNode.classList.add("active");
                }
                else{
                  nextNode.classList.remove('active');
                }
        }
    }
    if (isError) {
        return <PageNotFound/>
    } else {
        const {name, content, content2, metatags, slider} = {...simplePageObject}
        return (
            <div className="container">
                <div className={clsx("wrapper animate animateY", !isFetching && 'animated')}>
                {
                    (isFetching) ? <Loader/> :
                        <>
                            <Meta {...metatags} />


                                <h1>{name}</h1>
                                <div className="group">
                                    <div onClick={clickHandler}
                                         dangerouslySetInnerHTML={{__html: content}}/>
                                    {
                                        (slider) ? <SliderSimplePage slider={slider}/> : null
                                    }

                                    <div onClick={clickHandler}
                                         dangerouslySetInnerHTML={{__html: content2}}/>
                                </div>

                        </>
                }
                </div>

            </div>
        );
    }


};
