import * as React from 'react';
import {useGetReviewByIdQuery} from "../../services/reviews";
import {useParams} from "react-router";
import clsx from "clsx";
import {ReviewBlockListItem} from "./ReviewList";
import {Link} from "react-router-dom";
import {PageNotFound} from "../404/404";
import {Meta} from "../base/Meta";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {toggleReviewDialog} from "../dialog/dialogSlice";
import {Loader} from "../loader/Loader";
import {BreadCrumbsBlock} from "../simple/BreadCrumbsBlock";


const ReviewItem = (): JSX.Element => {
    const dispatch = useAppDispatch();
    let {pk} = useParams();
    const {data: review, isSuccess, isLoading, isFetching, isError} = useGetReviewByIdQuery({
        pk: Number(pk)
    });
    const handleReview = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        dispatch(toggleReviewDialog());
    };

    if (isError) {
        return <PageNotFound/>
    }
    return (
        <>
            <div className="container">
                <div className={clsx("wrapper animate animateY", !isLoading && 'animated')}>
                    {
                        (isFetching) ? <Loader/> :
                            <>
                                <BreadCrumbsBlock items={[
                                    {name: 'Отзывы', link: '/reviews'},
                                    {name: review.name, link: review.slug}
                                ]}/>
                                {
                                    (isSuccess) ?
                                        <>
                                            <Meta title={`Отзыв от ${review.name}`} {...review.metatags} />
                                            <div className="container__header flex space-between align-item">
                                                <h1>Отзыв от {review.name}</h1>
                                                <a href="#" className="btn btn-yellow" onClick={handleReview}>Оставить
                                                    отзыв</a>
                                            </div>
                                            <div className="resporence">
                                                <ReviewBlockListItem item={review}/>
                                            </div>
                                            <div className={'more-block mt-20'}>
                                                <Link className='btn btn-yellow' to={'/reviews'}>Все отзывы</Link>
                                            </div>
                                        </> : null
                                }
                            </>
                    }
                </div>
            </div>
        </>
    );
};

export default ReviewItem;

