import {api} from './api';


interface QueryTpArg {
    branch?: number,
    city?: number,
    tp?: number,
    group?: boolean
}

interface IdArg {
    id: number;
}

export const postsApi = api.injectEndpoints({
    endpoints: build => ({
        getServiceAge: build.query<Array<ItemAge>, void | QueryTpArg>({
            query: ({
                        branch, city, group, tp
                    }: QueryTpArg) => `/service/age/?city=${(city) ? city: ''}&branch=${(branch) ? branch: ''}${(group) ? '&group=1': ''}${(tp) ? `&tp=${tp}`: ''}`,
            providesTags: data =>
                data
                    ? [
                        ...data.map(({id}) => ({
                            type: 'Services' as const,
                            id,
                        })),
                        {type: 'Services', id: 'AGE-LIST'},
                    ]
                    : [...[], {type: 'Services', id: 'AGE-LIST'}],
        }),
        getServiceGroup: build.query<Array<ItemGroup>, void | QueryTpArg>({
            query: ({
                        branch,
                        tp
                    }: QueryTpArg) => `/service/group/?branch=${branch}&tp=${(tp) ?tp: ''}`,
            providesTags: data =>
                data
                    ? [
                        ...data.map(({id}) => ({
                            type: 'Services' as const,
                            id,
                        })),
                        {type: 'Services', id: 'GROUP-LIST'},
                    ]
                    : [...[], {type: 'Services', id: 'GROUP-LIST'}],
        }),
        getServiceById: build.query<ItemService, void | IdArg>({
            query: ({id}: IdArg) => `/service/service/${id}/?fields=id,name`,
            providesTags: data => [{ type: 'Services', id: 'SERVICE-ITEM' }]
        }),
    }),
});

export const {useGetServiceAgeQuery, useGetServiceGroupQuery, useGetServiceByIdQuery} = postsApi;

export const {
    endpoints: {getServiceAge, getServiceGroup, getServiceById},
} = postsApi;
