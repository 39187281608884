import React from "react";
import {Helmet} from "react-helmet";

interface MetaProp {
    title?: string,
    keywords?: string,
    description?: string,
}

export const Meta = (props: MetaProp): JSX.Element => {
    let {title, keywords, description} = props
    return (
        <Helmet>
            <title>{title || 'Детский центр раннего развития "Top Kids" частный детский сад'}</title>
            <meta
                name="keywords"
                content={keywords || "Детский сад, Раннее развитие, Педагогика Монтессори, дети, частный садик, франшиза"}
            />
            <meta
                name="description"
                content={description || "Отличные педагоги и воспитали, Ваш ребенок в надежных и заботливых руках. Для того чтобы записать ребёнка в детский центр раннего развития или в частный детский сад, выберите город и удобный адрес."}
            />

        </Helmet>

    );
}
