import {api} from './api';

export const postsApi = api.injectEndpoints({
    endpoints: build => ({
        getReviews: build.query<ListResponse<ItemReview>, void | QueryArg>({
            query: ({
                        offset,
                        limit,
                        branch,
                        city
                    }: QueryArg) => `/about/review?offset=${offset}&limit=${limit}&city=${city}&branch=${(branch) ? branch: ''}&omit=metatags`,
            providesTags: data =>
                data
                    ? [
                        ...data.results.map(({id}) => ({
                            type: 'Reviews' as const,
                            id,
                        })),
                        {type: 'Reviews', id: 'REVIEW-LIST'},
                    ]
                    : [...[], {type: 'Reviews', id: 'REVIEW-LIST'}],
        }),
        getReviewById: build.query<ItemReview, void | QueryPkArg>({
            query: ({pk}: QueryPkArg) => `/about/review/${pk}/`,
            providesTags: data => [{ type: 'Reviews', id: 'REVIEW-ITEM' }]
        }),
    }),
});

export const {useGetReviewsQuery, useGetReviewByIdQuery} = postsApi;

export const {
    endpoints: {getReviews, getReviewById},
} = postsApi;
