import * as React from 'react';
import {useEffect, useState} from 'react';
import SelectBranch from "../base/form/SelectBranch";
import {useAppSelector} from "../../hooks/useAppSelector";
import {useGetServiceAgeQuery, useGetServiceGroupQuery} from "../../services/services";
import clsx from "clsx";
import {mainBranch} from "../dialog/components/cityReducer";
import {Loader} from "../loader/Loader";
import AnimateHeight from "react-animate-height";
import {BrowserView, MobileView} from 'react-device-detect';

interface SheduleBlockProp {
    tp?: number,
    hide_h?: boolean,
    not_text?: string
}

export const SheduleMobileItem = (props: { item: ItemGroup }): JSX.Element => {
    const groupItem = props.item;
    const [isOpen, setOpen] = useState(false);
    const [height, setHeight] = useState<any>(0);

    function toggleOpen() {
        setHeight(height === 0 ? "auto" : 0);
        setOpen(!isOpen);
    }

    function getDay(day: number) {
        const days = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']
        return days[day]
    }

    return (
        <div className="modal__lesson_box">
            <div
                className={clsx("modal__lesson_head", isOpen && 'active')}
                aria-expanded={height !== 0}
                aria-controls={`shedule-group-${groupItem.id}`}
                onClick={toggleOpen}
            >{groupItem.name}</div>
            <AnimateHeight
                id={`shedule-group-${groupItem.id}`}
                duration={500}
                height={height}
            >
                <div className="modal__lesson_block">
                    {[1, 2, 3, 4, 5, 6, 7].map((day) => {
                            const events = groupItem.events;
                            const events_day = events.filter((item: ItemGroupEvent) => item.day == day)
                            let time = ''
                            if (events_day.length) {
                                time = events_day[0].time + ' - ' + events_day[0].time_
                                return (
                                    <div className="modal__lesson_data">{getDay(day)}: {time}</div>
                                )
                            }
                        }
                    )
                    }
                    {(groupItem.cost_month) ?
                        <div className="modal__lesson_summ">
                            Стоимость, месяц: {groupItem.cost_month}
                        </div> : null}
                    {(groupItem.cost) ?
                        <div className="modal__lesson_summ">
                            Стоимость, занятия: {groupItem.cost}
                        </div> : null}
                    {(groupItem.number_lesson) ?
                        <div className="modal__lesson_summ">
                            Количество занятий: {groupItem.number_lesson}
                        </div> : null}
                </div>
            </AnimateHeight>
        </div>

    )
};

export const SheduleBlock = (props: SheduleBlockProp): JSX.Element => {
    const branch = useAppSelector(mainBranch);
    const [ageId, setAgeId] = useState(0);
    const [ageList, setAgeList] = useState([]);
    const [groupList, setList] = useState([]);

    const {data: ageObjects, isSuccess: isSuccessAge} = useGetServiceAgeQuery({
        branch: branch.id,
        group: true,
        tp: props.tp
    });
    useEffect(() => {
        if (isSuccessAge) {
            setAgeList([...ageObjects]);
        }
    }, [ageObjects]);

    const {data: objects, isLoading, isSuccess, isFetching, isError} = useGetServiceGroupQuery({
        branch: branch.id,
        tp: props.tp
    });
    useEffect(() => {
        setAgeId(0)
    }, [branch.id]);

    useEffect(() => {
        if (isSuccess) {
            setList([...objects]);
        }
    }, [objects]);

    const handleClickAge = (ageItem: ItemAge) => {
        setAgeId(ageItem.id)
    };
    let groupListFilter = groupList;
    if (ageId) {
        groupListFilter = groupListFilter.filter(item => item.age === ageId)
    }
    return (
        <div>
            {
                (props.hide_h) ? null : <h2>Расписание занятий</h2>
            }

            <SelectBranch/>
            {
                (isFetching) ? <Loader/> :
                    <>
                        <div className="modal__lesson-header flex space-between">
                            <div className="modal__lesson-time">Время работы: {branch.work}</div>
                            <div className="modal__lesson-phone">Тел: <a
                                href={`tel:${branch.phone.replace(/\D/g, '')}`}>{branch.phone}</a></div>
                            <div className="modal__lesson-email">E-mail: <a
                                href={`mailto:${branch.email}`}>{branch.email}</a></div>
                        </div>
                        <div className="modal__lesson-header">
                            {ageList.map((age) => {
                                return (
                                    <label className={'age'} key={age.id} onClick={() => handleClickAge(age)}>
                                        <div className={clsx("jq-radio", age.id === ageId && "checked")}/>
                                        <span>{age.name}</span>
                                    </label>
                                );
                            })}
                        </div>
                        <BrowserView>
                            <div className="modal__lesson-table">
                                <table>
                                    <tbody>
                                    <tr>
                                        <th>Тип занятия, <br/>возраст</th>
                                        <th className='hide-lsee-lg hide-lsee-md'>Стоимость, месяц</th>
                                        <th className='hide-lsee-lg hide-lsee-md'>Стоимость, занятия</th>
                                        <th className='hide-lsee-lg hide-lsee-md'>Количество занятий</th>
                                        <th>Пн</th>
                                        <th>Вт</th>
                                        <th>Ср</th>
                                        <th>Чт</th>
                                        <th>Пт</th>
                                        <th>Сб</th>
                                        <th>Вс</th>
                                    </tr>
                                    {groupListFilter.map((groupItem) => {
                                        return (
                                            <tr key={groupItem.id}>
                                                <td><label><input type="checkbox"/>{groupItem.name}</label></td>
                                                <td className='hide-lsee-lg hide-lsee-md'>{(groupItem.cost_month) ? groupItem.cost_month : '—'}</td>
                                                <td className='hide-lsee-lg hide-lsee-md'>{(groupItem.cost) ? groupItem.cost_month : '—'}</td>
                                                <td className='hide-lsee-lg hide-lsee-md'>{(groupItem.number_lesson) ? groupItem.number_lesson : '—'}</td>
                                                {[1, 2, 3, 4, 5, 6, 7].map((day) => {
                                                        const events = groupItem.events;
                                                        const events_day = events.filter((item: ItemGroupEvent) => item.day == day)
                                                        let time = ''
                                                        if (events_day.length) {
                                                            time = events_day[0].time + ' - ' + events_day[0].time_
                                                        }
                                                        return (<td key={day} className={clsx(time != '' && "blue")}>
                                                            {time}
                                                        </td>)
                                                    }
                                                )
                                                }
                                            </tr>
                                        );
                                    })}
                                    {
                                        (groupListFilter.length == 0) ? <tr>
                                            <td colSpan={11}><label>{props.not_text}</label></td>
                                        </tr> : null
                                    }

                                    </tbody>
                                </table>
                            </div>
                        </BrowserView>
                        <MobileView>
                            <div className="modal__lesson-mobile">

                                {groupListFilter.map((groupItem) => {
                                    return (
                                        <SheduleMobileItem key={groupItem.id} item={groupItem}/>
                                    )
                                })}
                                {
                                    (groupListFilter.length == 0) ?
                                        <div className="price__mobile-block-no"><b>{props.not_text}</b></div> : null
                                }
                            </div>
                        </MobileView>
                    </>
            }

        </div>
    );
};

SheduleBlock.defaultProps = {
    tp: null,
    hide_h: false,
    not_text: 'Расписания пока нет, но оно скоро появится на сайте!'
}


