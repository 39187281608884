import * as React from 'react';
import {SheduleBlock} from "../../shedule/SheduleBlock";

interface TimeTableProp {
    tp?: number,
    hide_h?: boolean
}

const limit = 100;
export const TimeTableDialog = (props: TimeTableProp): JSX.Element => {
    return (
        <div>
            {
                (props.hide_h) ? null : <h2>{(props.tp === 10) ? 'Расписание занятий детского сада' : 'Расписание занятий центра раннего развития'}</h2>
            }
            <SheduleBlock tp={props.tp} hide_h={true}/>

        </div>
    );
};

TimeTableDialog.defaultProps = {
    tp: 20,
    hide_h: false,
}


