import * as React from 'react';
import {useEffect, useState} from 'react';
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {mainBranch} from "./cityReducer";
import {useGetServiceAgeQuery, useGetServiceGroupQuery} from "../../../services/services";
import SelectBranch from "../../base/form/SelectBranch";
import clsx from "clsx";
import {PriceBlock} from "../../price/PriceBlock";

interface PriceTableProp {
    tp?: number,
    hide_h?: boolean
}

const limit = 100;
export const PriceDialog = (props: PriceTableProp): JSX.Element => {
    return (
        <div>
            {
                (props.hide_h) ? null : <h2>Прайс на услуги</h2>
            }
            <PriceBlock tp={props.tp} hide_h={true}/>
        </div>
    );
};

PriceDialog.defaultProps = {
    tp: null,
    hide_h: false,
}


