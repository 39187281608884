import * as React from 'react';
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {useGetServiceByIdQuery} from "../../../services/services";
import {FormInput} from "../../base/form/FormInput";
import {PrivacyForm} from "../../base/form/PrivacyForm";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {FormFioProp, resetForm, saveFormFio, selectFormFio} from "../../base/form/FormFioSlice";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {toggleDialogThank} from "../dialogSlice";
import {mainBranch} from "./cityReducer";

interface LessonDialogProp {
    id?: number,
}

export const LessonDialog = (props: LessonDialogProp): JSX.Element => {
    const dispatch = useAppDispatch();
    const {id} = props
    const {data, isSuccess, isLoading, isFetching, isError} = useGetServiceByIdQuery({
        id: id,
    });
    const branch = useAppSelector(mainBranch);
    const [successSend, setSuccessSend] = useState(false);
    const {
        register,
        setValue,
        setError,
        handleSubmit,
        clearErrors,
        reset,
        control,
        formState: {errors}
    } = useForm<FormFioProp>(
        {defaultValues: {branch: branch.id}}
    );

    useEffect(() => {
        setSuccessSend(false)
        return function cleanup() {
            dispatch(resetForm())
        };
    }, []);

    const form = useAppSelector(selectFormFio)
    const onSubmit = handleSubmit(dt => {
        if (data?.id){
            dt['service']=[data.id,]
        }

        dispatch(saveFormFio(dt));
    });


    useEffect(() => {
        clearErrors()
        if (form.error) {
            let err: any[] = []
            Object.entries(form.error)
                .map(([key, value]) => {
                    err.push(
                        {
                            type: "manual",
                            name: key,
                            message: value
                        }
                    )
                })
            err.forEach(({name, type, message}) =>
                setError(name, {type, message})
            );
        } else {
            clearErrors()
        }


    }, [form.error]);

    useEffect(() => {
        if (
            form.data.id && !successSend
        ) {
            setSuccessSend(true)
            dispatch(toggleDialogThank({name: form.data.name, isOpen: true, component:'ServiceDialogThanks'}))
        }
    }, [form.data]);

    return (
        <div className={"modal-fill-form__wrapper"}>
            <form className={'modal-choose-forms bg modal-fill-form__form'} onSubmit={onSubmit}>
                <h3 className="modal-fill-form__title">Записаться на занятие<br/>{data?.name}</h3>
                <div className="modal-fill-form__elements">
                    <FormInput className={'w-100'} name={'name'} errors={errors} register={register}
                               placeholder="Ваше имя"/>
                    <FormInput className={'w-100'} name={'phone'} errors={errors} register={register}
                               placeholder="Ваш телефон" mask="+7 999 999 99 99"
                               control={control}
                    />
                    <FormInput className={'w-100'} name={'email'} errors={errors} register={register}
                               placeholder="Ваш email"/>
                </div>
                <button className="headlist-btn modal-fill-form__sbmt" type="submit">Записаться</button>
                <p className="modal-fill-form__caption">
                    <PrivacyForm/>
                </p>
            </form>
        </div>
    );
};


