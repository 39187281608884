import * as React from 'react';
import {useEffect, useState} from 'react';
import {useAppSelector} from "../../hooks/useAppSelector";
import {useGetServiceAgeQuery, useGetServiceGroupQuery} from "../../services/services";
import SelectBranch from "../base/form/SelectBranch";
import {mainBranch} from "../dialog/components/cityReducer";
import AnimateHeight from "react-animate-height";
import clsx from "clsx";
import {Loader} from "../loader/Loader";
import {BrowserView, MobileView} from 'react-device-detect';

interface PriceTableProp {
    tp?: number,
    hide_h?: boolean,
    not_text?: string
}

export const PriceMobileItem = (props: { item: ItemGroup }): JSX.Element => {
    const groupItem = props.item;
    const [isOpen, setOpen] = useState(false);
    const [height, setHeight] = useState<any>(0);

    function toggleOpen() {
        setHeight(height === 0 ? "auto" : 0);
        setOpen(!isOpen);
    }

    return (
        <div className="price__mobile-box">
            <div
                className={clsx("price__mobile-head", isOpen && 'active')}
                aria-expanded={height !== 0}
                aria-controls={`price-group-${groupItem.id}`}
                onClick={toggleOpen}
            >{groupItem.name}</div>
            <AnimateHeight
                id={`price-group-${groupItem.id}`}
                duration={500}
                height={height}
            >
                <div className="price__mobile-block">
                    <div className="flex">
                        <span>Стоимость, месяц</span>
                        <b>{(groupItem.cost_month) ? groupItem.cost_month : '—'}</b>
                    </div>

                    <div className="flex">
                        <span>Стоимость, занятия</span>
                        <b>{(groupItem.cost) ? groupItem.cost : '—'}</b>
                    </div>

                    <div className="flex">
                        <span>Количество занятий</span>
                        <b>{(groupItem.number_lesson) ? groupItem.number_lesson : '—'}</b>
                    </div>
                </div>
            </AnimateHeight>
        </div>

    )
};

export const PriceBlock = (props: PriceTableProp): JSX.Element => {
    const branch = useAppSelector(mainBranch);
    const [ageId, setAgeId] = useState(0);
    const [ageList, setAgeList] = useState([]);
    const [groupList, setList] = useState([]);

    const {data: ageObjects, isSuccess: isSuccessAge} = useGetServiceAgeQuery({
        branch: branch.id,
        group: true,
        tp: props.tp
    });
    useEffect(() => {
        if (isSuccessAge) {
            setAgeList([...ageObjects]);
        }
    }, [ageObjects]);

    const {data: objects, isLoading, isSuccess, isFetching, isError} = useGetServiceGroupQuery({
        branch: branch.id,
        tp: props.tp
    });

    useEffect(() => {
        setAgeId(0)
    }, [branch.id]);

    useEffect(() => {
        if (isSuccess) {
            setList([...objects]);
        }
    }, [objects]);

    const handleClickAge = (ageItem: ItemAge) => {
        setAgeId(ageItem.id)
    };
    let groupListFilter = groupList;
    if (ageId) {
        groupListFilter = groupListFilter.filter(item => item.age === ageId)
    }
    return (
        <div className="price">
            {
                (props.hide_h) ? null : <h2>Прайс на услуги</h2>
            }
            <SelectBranch/>
            {
                (isFetching) ? <Loader/> :
                    <>
                        <div className="modal__lesson-header">
                            <label className={'age'} onClick={() => setAgeId(0)}>
                                <div className={clsx("jq-radio", 0 === ageId && "checked")}/>
                                <span>Все возраста</span>
                            </label>
                            {ageList.map((age) => {
                                return (
                                    <label className={'age'} key={age.id} onClick={() => handleClickAge(age)}>
                                        <div className={clsx("jq-radio", age.id === ageId && "checked")}/>
                                        <span>{age.name}</span>
                                    </label>
                                );
                            })}
                        </div>
                        <div
                            className="price__header">{(props.tp === 10) ? 'Детский сад «TopKids»' : 'Центр раннего развития «TopKids»'}</div>
                        <BrowserView>
                        <div className="price__table">
                            <table>
                                <tbody>
                                <tr>
                                    <th>Тип занятия, <br/>возраст</th>
                                    <th>Стоимость, месяц</th>
                                    <th>Стоимость, занятия</th>
                                    <th>Количество занятий</th>
                                </tr>

                                {groupListFilter.map((groupItem) => {
                                    return (
                                        <tr key={groupItem.id}>
                                            <td>{groupItem.name}</td>
                                            <td>{(groupItem.cost_month) ? groupItem.cost_month : '—'}</td>
                                            <td>{(groupItem.cost) ? groupItem.cost : '—'}</td>
                                            <td>{(groupItem.number_lesson) ? groupItem.number_lesson : '—'}</td>
                                        </tr>
                                    );
                                })}
                                {
                                    (groupListFilter.length == 0) ? <tr>
                                        <td colSpan={4}>{props.not_text}</td>
                                    </tr> : null
                                }

                                </tbody>
                            </table>
                        </div>
                        </BrowserView>
                        <MobileView>
                            <div className="price__mobile">
                                {groupListFilter.map((groupItem) => {
                                    return (
                                        <PriceMobileItem key={groupItem.id} item={groupItem}/>
                                    )
                                })}
                                {
                                    (groupListFilter.length == 0) ?
                                        <div className="price__mobile-block-no"><b>{props.not_text}</b></div> : null
                                }
                            </div>
                        </MobileView>
                    </>
            }
        </div>
    );
};

PriceBlock.defaultProps = {
    tp: 20,
    hide_h: false,
    not_text: 'Прайса пока нет, но он скоро появится на сайте!'
}


