import * as React from 'react';
import {useGetNewsByIdQuery} from "../../services/news";
import {useParams} from "react-router";
import clsx from "clsx";
import {Link} from "react-router-dom";
import Moment from "moment";
import {Meta} from "../base/Meta";
import {PageNotFound} from "../404/404";
import {resizeSrc} from "../../utils/utils";
import {Loader} from "../loader/Loader";
import {BreadCrumbsBlock} from "../simple/BreadCrumbsBlock";


const ArticleItem = ({ttp}: { ttp: string }): JSX.Element => {
    let {slug} = useParams();
    const {data: article, isSuccess, isFetching, isLoading, isError} = useGetNewsByIdQuery({
        slug: slug,
        ttp: ttp
    });
    if (isError) {
        return <PageNotFound/>
    }

    return (
        <>
            <div className="container">
                <div className={clsx("wrapper animate animateY", !isLoading && 'animated')}>
                    {
                        (isFetching) ? <Loader/> :
                            <>
                                <BreadCrumbsBlock items={[
                                    {name: 'Статьи', link: '/article'},
                                    {name: article.name, link: article.slug}
                                ]}/>
                                {
                                    (isSuccess) ?
                                        <section className="new-news-item">
                                            <Meta title={article.name} {...article.metatags} />
                                            <div className="new-news-item__header">
                                                <h1 className="new-news-item__title">{article.name}</h1>
                                            </div>
                                            <div className="new-news-item__media-wrapper">
                                                <div className="new-news-item__img-wrapper">
                                                    <img src={resizeSrc(article.image, 1150, 600)} alt={article.name}/>
                                                </div>
                                            </div>
                                            <div className="new-news-item__content" dangerouslySetInnerHTML={{__html: article.content}}/>
                                            <time className="new-news-item__date"
                                                          dateTime="2022-08-24T16:29">{Moment(article.created).format('DD MMMM YYYY')}</time>

                                            <div className={'more-block mt-20'}>
                                                <Link className='btn btn-yellow'
                                                      to={`/${ttp}`}>Все {(ttp == 'article' ? 'статьи' : 'новости и акции')}</Link>
                                            </div>
                                        </section> : null
                                }
                            </>
                    }
                </div>
            </div>
        </>
    );
};

export default ArticleItem;

