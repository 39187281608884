import React from "react";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {mainBranch} from "../../dialog/components/cityReducer";
import {toggleDialog} from "../../dialog/dialogSlice";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {SocialBlock} from "../SocialBlock";

export interface FormThanksProp {
    name: string
}

export const FormThanks = (props: FormThanksProp): JSX.Element => {
    const dispatch = useAppDispatch();
    const branch = useAppSelector(mainBranch);
    return (
        <React.Fragment>
            <div className="modal__thanks">
                <h2><span>{props.name}, спасибо!</span> Ваша заявка принята</h2>

                <div className="modal__thanks-title">Наши менеджеры свяжутся с Вами для уточнения деталей в
                    ближайшее время!
                </div>
                <button
                    className="headlist-btn thanks-for-review__good"
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(toggleDialog())
                    }}
                >Отлично</button>
            </div>
        </React.Fragment>

    );
}
