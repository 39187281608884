import * as React from 'react';

interface ItemBreadCrumbs {
    name: string,
    link: string,
}

interface BreadCrumbsBlockProp {
    children?: JSX.Element;
    items?: ItemBreadCrumbs[]
}

export const BreadCrumbsBlock = (props: BreadCrumbsBlockProp): JSX.Element => {
    return (
        <React.Fragment>

            <div className="new-pagination new-news-item__pagination">
                <ul className="new-pagination__links">
                    <li className="new-pagination__link">
                        <a href="/">Главная</a>
                    </li>
                    {props.children}
                    {
                        (props.items) ?
                            <React.Fragment>
                                {props.items.map((item: ItemBreadCrumbs) => {
                                    return (
                                        <li key={item.link} className="new-pagination__link">
                                            <a href={item.link}>{item.name}</a>
                                        </li>

                                    );
                                })}
                            </React.Fragment> : null
                    }
                </ul>
            </div>
        </React.Fragment>
    );
};
