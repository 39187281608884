import React from "react";
import clsx from "clsx";
import Pagination from "react-js-pagination";

interface PaginationBaseProp {
    counts: number,
    limit: number,
    currentPage: number,
    handlePageChange: any,
    branch: number,
    ttp: string

}

export const PaginationBase = (props: PaginationBaseProp): JSX.Element => {
    const {counts, limit, currentPage, handlePageChange, branch, ttp} = props
    return (
        <>
            {
                (counts && counts / limit > 1) ?
                    <div
                        className="resporence__pagination pagination__full flex space-between align-item">
                        <a className={clsx("prev-btn", (currentPage - 1) <= 0 && 'disable')}
                           href="#"
                           onClick={(e) => {
                               e.preventDefault();
                               handlePageChange(currentPage - 1);
                           }}></a>
                        <Pagination
                            key={branch}
                            activePage={currentPage}
                            itemsCountPerPage={limit}
                            totalItemsCount={counts}
                            pageRangeDisplayed={10}
                            itemClass="page-item"
                            itemClassPrev="page-prev"
                            prevPageText={"←"}
                            itemClassNext="page-next"
                            nextPageText={"→"}
                            itemClassFirst="page-first"
                            itemClassLast="page-last"
                            linkClass="page-link"
                            activeClass="active"
                            activeLinkClass="active"
                            hideFirstLastPages={true}
                            onChange={handlePageChange}
                            getPageUrl={(i) => `/${ttp}?page=${i}`}
                        />
                        <a className={clsx("next-btn", (currentPage + 1) > (counts / limit) && 'disable')}
                           href="#" onClick={(e) => {
                            e.preventDefault();
                            handlePageChange(currentPage + 1);
                        }}></a>
                    </div> : null
            }
        </>

    );
}
