import * as React from 'react';
import {useEffect, useState} from 'react';
import {useGetVacancyQuery} from "../../../services/vacancy";
import Moment from "moment";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import SelectBranch from "../../base/form/SelectBranch";
import AnimateHeight from "react-animate-height";
import {toggleVacancyDialog} from "../../dialog/dialogSlice";

interface VacancyBlockProp {
    title: string,
    limit: number,
    branch: number,
    city: number,
}

export const VacancyBlockItem = (props: { item: ItemVacancy }): JSX.Element => {
    const dispatch = useAppDispatch();
    const item = props.item;
    const [isOpen, setOpen] = useState(false);
    const [height, setHeight] = useState<any>(0);

    function toggleOpen() {
        setHeight(height === 0 ? "auto" : 0);
        setOpen(!isOpen);
    }
    const handleVacancy = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        dispatch(toggleVacancyDialog(
            {
                    pid: item.id,
                    params: item
                }
        ));
    };

    return (
        <div className="headanswer__box">
            <div className={`headanswer-head ${(isOpen) ? 'active' : ''}`} onClick={toggleOpen}>{item.name}</div>
            <AnimateHeight
                id={`vakancy-${item.id}`}
                duration={500}
                height={height}
            >
                <div className={`headanswer-block not_none`}>
                    <div className="vakancy__actual-summ">{item.salary}</div>

                    <div className="vakancy__actual-flex flex">
                        {
                            (item.text1) ? <div className="vakancy__actual-block">
                                <div className="vakancy__actual-head">В TopKids вы приобретете:</div>

                                <div dangerouslySetInnerHTML={{__html: item.text1}}/>
                            </div> : null
                        }
                        {
                            (item.text2) ? <div className="vakancy__actual-block">
                                <div className="vakancy__actual-head">В TopKids вы научитесь:</div>

                                <div dangerouslySetInnerHTML={{__html: item.text2}}/>
                            </div> : null
                        }
                        {
                            (item.text3) ? <div className="vakancy__actual-block">
                                <div className="vakancy__actual-head">Обязанности:</div>

                                <div dangerouslySetInnerHTML={{__html: item.text3}}/>
                            </div> : null
                        }
                        {
                            (item.text4) ? <div className="vakancy__actual-block">
                                <div className="vakancy__actual-head">Пожелания к кандидатам:</div>

                                <div dangerouslySetInnerHTML={{__html: item.text4}}/>
                            </div> : null
                        }
                    </div>
                    <div className="vakancy__actual-bottom flex align-item space-between">
                        <a href="" className="btn btn-yellow" onClick={handleVacancy}>Откликнуться</a>
                        <div className="vakancy__actual-data">Вакансия
                            опубликована {Moment(item.created).format('DD.MM.YYYY')}</div>
                    </div>


                </div>
            </AnimateHeight>
        </div>


    )
};

export const VacancyBlock = (props: VacancyBlockProp): JSX.Element => {
    const dispatch = useAppDispatch();
    const {limit, branch, city} = props
    const [nextUrl, setNextUrl] = React.useState('');
    const [currentUrl, setCurrentUrl] = React.useState('');
    const [objectsList, setList] = useState([]);
    const {data: objects, isLoading, isSuccess, isFetching, isError} = useGetVacancyQuery({
        limit: limit,
        offset: 0,
        branch: branch,
        url: currentUrl
    });

    useEffect(() => {
        setCurrentUrl('');
    }, [branch]);

    useEffect(() => {
        if (objects) {
            if (currentUrl) {
                setList([...objectsList, ...objects.results]);
            } else {
                setList([...objects.results]);
            }
            setNextUrl(objects.next);
        }
    }, [objects,]);

    return (
        <div className="vakancy__actual">
            <div className="vakancy__actual-wrapper">
                <h2>{props.title}</h2>
                <div className="vakancy__actual-title">Введите город, в котором ищите вакансию, либо выберите подходящую
                    должность
                </div>
                <SelectBranch/>
                <div className="headanswer-boxes">
                    {
                        (!objectsList.length) ?
                            <div className="vakancy__actual-title">В выбранном филиале вакансий не найдено.</div> : null
                    }
                    {objectsList.map((item) => {
                        return (
                            <div key={item.id} className='news-grid__content'>
                                <VacancyBlockItem item={item}/>
                            </div>
                        );
                    })}
                </div>
                {nextUrl && (
                    <div className={'headanswer-more-block'}>
                        <button
                            className='headanswer-more'
                            onClick={() => setCurrentUrl(nextUrl)}
                        >
                            Показать еще
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

VacancyBlock.defaultProps = {
    title: 'Актуальные вакансии',
    limit: 10,
}


