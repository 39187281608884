import * as React from 'react';
import SlickSlider from "react-slick";
import {cropSrc} from "../../utils/utils";

interface SliderSimplePageProp {
    slider: SliderPage[]
}

const settings = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
};

export const PhotoSliderItem = (props: { item: SliderPage }): JSX.Element => {
    const item = props.item;
    const src_image = cropSrc(item.image, 1150, 500)
    return (
        <div className={'slider-block'}>
            {
                (item.content) ?
                    <div className="slider-title">{item.content}</div> : null
            }

            <img src={src_image} alt={item.name}/>
        </div>
    )
};

export const SliderSimplePage = ({slider}: SliderSimplePageProp): JSX.Element => {
    return (
        <div className="group-slider">
            <div className="group__slider slider">
                <SlickSlider {...settings}>
                    {slider.map((photo, index) => {
                        return (
                            <React.Fragment key={photo.id}>
                                <PhotoSliderItem item={photo}/>
                            </React.Fragment>

                        );
                    })}
                </SlickSlider>
            </div>
        </div>
    )
};
