import React from "react";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {mainBranch} from "./cityReducer";
import {toggleDialog} from "../dialogSlice";
import {EmployeeBlock} from "../../vacancy/components/EmployeeBlock";
import {SocialBlock} from "../../base/SocialBlock";

export interface DialogThanksProp {
    name: string,
    forName?: string,
    children?: JSX.Element;
    helpText?: string
}

export const DialogThanks = (props: DialogThanksProp): JSX.Element => {
    const dispatch = useAppDispatch();
    const branch = useAppSelector(mainBranch);
    return (
        <React.Fragment>
            <div className="modal__thanks">
                <h2><span>{props.name}, спасибо!</span> {props.forName}</h2>

                <div className="modal__thanks-title">
                    {
                        (props.children) ? props.children : props.helpText
                    }
                </div>
                <div className="bottom-menu__col thanks-for-review__info">
                    <span className="bottom-menu__title2">Мы в соцсетях</span>
                    <SocialBlock branch={branch}/>
                </div>
            </div>
        </React.Fragment>

    );
}

DialogThanks.defaultProps = {
    forName: 'Ваша заявка принята',
    helpText: 'Наши менеджеры свяжутся с Вами для уточнения деталей в ближайшее время!',
}
