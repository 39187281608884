import * as React from 'react';
import {useBranchContext} from "../../../context/BranchContext";
import {FormMail} from "../../base/form/FormMail";


export const MailDialog = (): JSX.Element => {
    const {branch, city} = useBranchContext();
    return (
        <div className={"leave-comment__wrapper modal-choose-forms bg"}>
            <FormMail branch={branch} tp={50}/>
        </div>
    );
};


