import * as React from 'react';
import {useEffect, useState} from 'react';
import {useBranchContext} from "../../context/BranchContext";
import {cropSrc} from "../../utils/utils";
import {useGetInteriorQuery} from "../../services/interior";
import {SimpleBlock} from "../simple/SimpleBlock";
import SelectBranch from "../base/form/SelectBranch";
import clsx from "clsx";

export const InteriorPhotoItem = (props: { item: ItemInterior, onClick: React.MouseEventHandler }): JSX.Element => {
    const item = props.item;
    const src_image = cropSrc(item.image, 265, 150)

    return (
        <span className="control" style={{cursor: "pointer"}} onClick={props.onClick}>
            <img src={src_image} alt={item.name}/>
        </span>
    )
};

const InteriorList = (props: PageListProp): JSX.Element => {
    let {title, limit} = props
    const {branch, city} = useBranchContext();

    const [nextUrl, setNextUrl] = React.useState('');
    const [currentUrl, setCurrentUrl] = React.useState('');
    const [objectsList, setList] = useState([]);
    const {data: objects, isLoading, isFetching} = useGetInteriorQuery({
        limit: limit,
        branch: branch,
        url: currentUrl
    });

    const [mainPhoto, setMainPhoto] = React.useState({
        name: '',
        image: '',
        tur: ''
    });

    useEffect(() => {
        setCurrentUrl('');
    }, [branch]);

    useEffect(() => {
        if (objects) {
            if (currentUrl) {
                setList([...objectsList, ...objects.results]);
            } else {
                setList([...objects.results]);
                setMainPhoto(objects.results[0])
            }
            setNextUrl(objects.next);
        }
    }, [objects]);

    return (
        <React.Fragment>
            <div className="container">
                <div  className={clsx("wrapper animate animateY", !isFetching && 'animated')}>
                    <SimpleBlock title={title}/>
                    <SelectBranch/>
                    {
                        (mainPhoto && mainPhoto.image) ?
                            <div className="slides">
                                <div className="slide">
                                    {
                                        (mainPhoto.tur) ?
                                            <iframe id="touriframe" width="100%" height="100%" src={mainPhoto.tur}
                                                    frameBorder="0"></iframe> :
                                            <a data-fancybox="gallery" href={mainPhoto.image}><img
                                                src={cropSrc(mainPhoto.image, 1150, 425)} alt={mainPhoto.name}/></a>
                                    }

                                </div>
                            </div> : null
                    }

                    <div className="controls">
                        {objectsList.map((interiorItem) => {
                            return (
                                <React.Fragment key={interiorItem.id}>
                                    <InteriorPhotoItem item={interiorItem} onClick={(e) => {
                                        setMainPhoto(interiorItem);
                                    }}/>
                                </React.Fragment>
                            );
                        })}
                    </div>
                    {nextUrl && (
                        <div className={'headanswer-more-block'}>
                            <button
                                className='headanswer-more'
                                onClick={() => setCurrentUrl(nextUrl)}
                            >
                                Показать еще
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default InteriorList;

const title = 'Интерьеры садов'
InteriorList.defaultProps = {
    title: title,
    limit: 30,
    metatags: {
        title: title
    }
}
