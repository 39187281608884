import * as React from 'react';
import {useBranchContext} from "../../../context/BranchContext";
import {FormCallBack} from "../../base/form/FormCallBack";


export const FreeLessonDialog = (): JSX.Element => {
    const {branch, city} = useBranchContext();
    return (
        <div className="modal__callback flex">
            <FormCallBack branch={branch} buttonName={'Записаться на занятие'} tp={30} children={
                <div className="modal__callback-box">
                    <h2><span>Записаться</span> на первое занятие</h2>

                    <div className="modal__callback-title">Пришлем вам всю необходимую информацию о занятиях и позвоним,
                        чтобы обсудить удобные время и место проведения занятия
                    </div>

                    <img src="/assets/images/modal-image1.jpg" alt="" className="modal__callback-img hide-lsee-md hide-lsee-lg"/>
                </div>
            }/>
        </div>
    );
};


