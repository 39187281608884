import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../../config/store';
import {serverAPIUrl} from "../../../services/api";


export interface FormResumeProp {
    first_name?: string,
    phone?: string,
    portfolio?: any,
    rezume?: any,
    vacancy?: number,
    branch?: number,
    prefix?: string
}

export const saveFormResume = createAsyncThunk(
    'about/resume',
    async (data: FormResumeProp, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        let formData = new FormData()
        for(const name in data) {
            // @ts-ignore
            if (name!='portfolio' && name!='rezume'){
                // @ts-ignore
                formData.append(name, data[name]);
            }else{
                // @ts-ignore
                const f = data[name][0]
                if (f){
                    formData.append(name, f);
                }

            }

          }
        try {
            const response = await fetch(`${serverAPIUrl}about/resume/`, {
                method: 'POST',
                body: formData,
            })

            const responseData = await response.json();

            if (response.status != 201) {
                return thunkAPI.rejectWithValue(responseData)
            } else {
                return thunkAPI.fulfillWithValue(responseData)
            }
        } catch (err) {
            return thunkAPI.rejectWithValue({serverError: err.toString()})
        }
    }
)

interface InitialState {
    data: any;
    error: any
}

const initialState: InitialState = {
    data: {
        id: null,
        name: '',
    },
    error: [],
}

export const formResumeSlice = createSlice({
    name: 'formresume',
    initialState: initialState,
    reducers: {
        resetResumeForm: (state: any) => {
            state.data = initialState;
            state.error = []
        },
    },
    extraReducers: (builder) => {
        builder.addCase(saveFormResume.fulfilled, (state: any, action: any) => {
            state.data = action.payload;
            state.error = []
        }).addCase(saveFormResume.rejected, (state: any, action: any) => {
            state.error = action.payload;
        })
    },
});


export const {
    resetResumeForm
} = formResumeSlice.actions;

export const selectFormResume = (state: RootState) => state.formresume;

export default formResumeSlice.reducer;