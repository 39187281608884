import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {FormInput} from "./FormInput";
import {FormTextArea} from "./FormTextarea";
import SelectBranch from "./SelectBranch";
import {toggleDialogThank} from "../../dialog/dialogSlice";
import {FormMailProp, resetMailForm, saveFormMail, selectFormMail} from "./FormMailSlice";
import {PrivacyForm} from "./PrivacyForm";


export const FormMail = (props: FormMailProp): JSX.Element => {
    const dispatch = useAppDispatch();
    const [successSend, setSuccessSend] = useState(false);
    const {
        register,
        setValue,
        setError,
        handleSubmit,
        clearErrors,
        reset,
        control,
        formState: {errors}
    } = useForm<FormMailProp>(
        {defaultValues: {...props,}}
    );

    useEffect(() => {
        setSuccessSend(false)
        return function cleanup() {
            dispatch(resetMailForm())
        };
    }, []);

    const form = useAppSelector(selectFormMail)
    const onSubmit = handleSubmit(data => {
        dispatch(saveFormMail(data));
    });

    useEffect(() => {
        clearErrors()
        if (form.error) {
            let err: any[] = []
            Object.entries(form.error)
                .map(([key, value]) => {
                    err.push(
                        {
                            type: "manual",
                            name: key,
                            message: value
                        }
                    )
                })
            err.forEach(({name, type, message}) =>
                setError(name, {type, message})
            );
        } else {
            clearErrors()
        }


    }, [form.error]);

    useEffect(() => {
        if (
            form.data.id && !successSend
        ) {
            setSuccessSend(true)
            dispatch(toggleDialogThank({name: form.data.name, isOpen: true}))
        }
    }, [form.data]);

    return (
        <React.Fragment>
            {
                (successSend) ?
                    <></> :
                    <React.Fragment>
                        <h2 className={'leave-comment__title'}>Напишите нам</h2>
                        <SelectBranch/>
                        <form onSubmit={onSubmit}>
                            <div className="flex space-between">
                                <FormInput name={'name'} errors={errors} register={register} placeholder="Ваше имя"/>
                                <FormInput name={'phone'} errors={errors} register={register}
                                           placeholder="Ваш телефон" mask="+7 999 999 99 99"
                                           control={control}
                                />
                                <FormInput name={'email'} errors={errors} register={register} placeholder="Ваш email"/>
                            </div>
                            <FormTextArea name={'text'} errors={errors} register={register} placeholder="Ваше сообщение"
                                          rows={10}/>
                            <div className="leave-comment__bottom">
                                <div className="leave-comment__final">
                                    <button className="btn btn-yellow leave-comment__submit" type="submit">Отправить
                                    </button>
                                </div>
                                <div className="leave-comment__privacy">
                                    <PrivacyForm/>
                                </div>
                            </div>
                        </form>
                    </React.Fragment>

            }
        </React.Fragment>

    );
}
