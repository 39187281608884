import * as React from 'react';
import {useEffect, useState} from 'react';
import {useAppDispatch} from '../../../hooks/useAppDispatch';
import {useGetAllBranchQuery, useGetBranchQuery, useGetCityQuery} from "../../../services/cities";
import {
    getStorageItem,
    mainBranch,
    mainCity,
    PositionLat,
    PositionLon,
    setBranch,
    setCity
} from "../../dialog/components/cityReducer";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {MainMenu} from "./MainMenu";
import {SelectCityButton} from "./SelectCityButton";
import {Link} from "react-router-dom";
import clsx from "clsx";
import {Sticky} from "react-sticky";
import {useOnClickOutside} from "usehooks-ts";
import {paramsMenu, toggleLetSee, toggleMenu} from "./headerSlice";
import {toggleCallBackDialog} from "../../dialog/dialogSlice";

const selectBranchLocalStorage = getStorageItem('branchId')


export const Header = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const clickRef = React.useRef();
    const {isOpen, isLetSee} = useAppSelector(paramsMenu);

    const latitude = useAppSelector(PositionLat);
    const longitude = useAppSelector(PositionLon);
    const {data: branches} = useGetAllBranchQuery();

    useEffect(() => {
        if (branches) {
            let blBranch;
            if (!getStorageItem('branchId')) {
                let blDistance = 100000;
                branches.find((branch_: BranchCity) => {
                    let lat = 0;
                    let lon = 0;
                    if (branch_.map) {
                        const coord = branch_.map.split(',')
                        lat = Number(coord[0]) || 0;
                        lon = Number(coord[1]) || 0;
                    }
                    if (lon && lat) {
                        const a = lat - latitude;
                        const b = lon - longitude;
                        const distance = Math.sqrt(a * a + b * b);
                        if (blDistance > distance) {
                            blDistance = distance
                            blBranch = branch_
                        }
                    }
                })
                if (blBranch) {
                    // @ts-ignore
                    dispatch(setCity(blBranch.city))
                    // @ts-ignore
                    dispatch(setBranch({...blBranch, city: blBranch.city}))
                }
            }
            if (!blBranch){
                let lbranches = branches.filter(item => (item.id === selectBranchLocalStorage))
                if (!lbranches.length || !selectBranchLocalStorage) {
                    lbranches = branches
                }
                blBranch = lbranches[0];
                // @ts-ignore
                dispatch(setCity(blBranch.city))
                // @ts-ignore
                dispatch(setBranch({...blBranch, city: blBranch.city}))
            }

        }

    }, [branches, latitude, longitude]);


    const city = useAppSelector(mainCity);
    const branch = useAppSelector(mainBranch);

    const now = new Date();
    const month = now.getMonth() + 1;
    let classHeader = 'header--winter'
    if (month >= 3) {
        classHeader = 'header--spring'
    }
    if (month >= 6) {
        classHeader = 'header--summer'
    }
    if (month >= 9) {
        classHeader = 'header--autumn'
    }
    if (month >= 12) {
        classHeader = 'header--winter'
    }

    const handleClickOutside = () => {
        if (isOpen) {
            dispatch(toggleMenu());
        }
    }
    useOnClickOutside(clickRef, handleClickOutside)

    const handleCallBack = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        dispatch(toggleCallBackDialog());
    };

    const handleLetSeeClick = () => {
        dispatch(toggleLetSee())
    };
    return (
        <Sticky topOffset={15}>
            {({style, isSticky}) => (
                <header className={clsx(`header ${classHeader}`, isSticky && 'fixed change')}>
                    <div className="header__box">
                        <div className="wrapper">
                            <div className="flex align-item space-between">
                                <div className="header__burger" onClick={() => dispatch(toggleMenu())}>
                                    <i/><i/><i/>
                                </div>
                                <SelectCityButton/>

                                <div className="header__logo">
                                    <Link to="/"><img src="/assets/images/logo.png" alt=""/></Link>
                                </div>

                                <div className="header__phone">
                                    
                                    <div className="header__phone-number nowrap"><a href="tel:">{branch.phone}</a></div>
                                    <a className="header__phone-callback"
                                       href="#" onClick={handleCallBack}>Заказать звонок</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <MainMenu toggleMenu={() => dispatch(toggleMenu())} isMenuOpen={isOpen} alias={'main'}
                              alias_burger={'main-burger'} menuRef={clickRef}/>
                </header>
            )}
        </Sticky>
    );
};


