import React, {useContext} from 'react';

interface BranchContext {
  branch: number;
  city: number
}

const defaultState = {
  branch: 0,
  city: 0
};

export const BranchContext = React.createContext<BranchContext>(defaultState);
export const useBranchContext = () =>  useContext(BranchContext);