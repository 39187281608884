import React from "react";
import {validURL} from "../../utils/utils";
import {Link} from "react-router-dom";

interface LinkOrUrlProp {
    url: string,
    title: string,
    class?: string,
}

export const LinkOrUrl = (props: LinkOrUrlProp): JSX.Element => {
    const isValidUrl = validURL(props.url)
    return (
        <React.Fragment>
            {
                (isValidUrl) ?
                    <a className={props.class} href={props.url} target={"_blank"} dangerouslySetInnerHTML={{__html: props.title}}></a> :
                    <Link className={props.class} to={props.url} dangerouslySetInnerHTML={{__html: props.title}}></Link>
            }
        </React.Fragment>

    );
}
