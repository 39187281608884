import * as React from 'react';
import {lazy, Suspense, useEffect, useLayoutEffect} from 'react';
import {Route, Routes, useLocation} from "react-router-dom";
import {Loader} from './features/loader/Loader';
import Dialog from "./features/dialog/Dialog";
import {Header} from "./features/home/components/Header";
import {Footer} from "./features/home/components/Footer";
import {useAppSelector} from "./hooks/useAppSelector";
import {mainBranch, mainCity, setGeoPosition} from "./features/dialog/components/cityReducer";
import {BranchContext} from "./context/BranchContext";
import Moment from "moment";
import {StickyContainer} from "react-sticky";
import ReviewList from "./features/reviews/ReviewList";
import ReviewItem from "./features/reviews/ReviewItem";
import ArticleItem from "./features/news/ArticleItem";
import Contacts from "./features/contacts/Contacts";
import Vacancy from "./features/vacancy/Vacancy";
import {EmployeeList} from "./features/employee/EmployeeList";
import {SimplePage} from "./features/simple/SimplePage";
import {paramsMenu, toggleMenu} from "./features/home/components/headerSlice";
import {useAppDispatch} from "./hooks/useAppDispatch";
import Schedule from "./features/shedule/Schedule";
import InteriorList from "./features/interior/InteriorList";
import GalleryList from "./features/gallery/GalleryList";
import GalleryItem from "./features/gallery/GalleryItem";
import Price from "./features/price/Price";
import {Cookie} from "./features/base/Cookie";
import NewsList from "./features/news/NewsList";
import NewsItem from "./features/news/NewsItem";
import {usePosition} from 'use-position';
import Branch from "./features/home/Branch";
import {
    defaultSetBodyClass,
    defaultGetSettings,
  } from '@n3/react-vision-panel';

const Home = lazy(() => import('./features/home/Home'));

type WrapperProps = {
    children: React.ReactNode;
};

const Wrapper = ({children}: WrapperProps) => {
    const location = useLocation();
    const {isOpen} = useAppSelector(paramsMenu);
    const dispatch = useAppDispatch();
    useLayoutEffect(() => {
        if (isOpen) {
            dispatch(toggleMenu())
        }
        window.scrollTo({left: 0, top: 0});
    }, [location.pathname]);
    return (
        <div>
            {children}
        </div>
    );
};

interface SettingsMap {
    [index: string]: string;
}

const App: React.FC = () => {
    const dispatch = useAppDispatch();
    const branch = useAppSelector(mainBranch);
    const city = useAppSelector(mainCity);
    useEffect(() => {
        Moment.locale('ru');
    }, []);
    const watch = false;
    const settings = {enableHighAccuracy: true, maximumAge: Infinity, timeout: 10}
    const {
        latitude, longitude, error
    } = usePosition(watch, settings);
    useEffect(() => {
        dispatch(setGeoPosition({lat: latitude, long: longitude}));
    }, [latitude, longitude]);
    let show = true;

    const settingsSee = defaultGetSettings()
    Object.keys(settingsSee)
    .forEach((property) => {
        defaultSetBodyClass(property, settingsSee[property]);
    });

    return (
        <Suspense fallback={<Loader/>}>
            <div id="main">
                <div className="section-container">
                    <Wrapper>
                        <StickyContainer>
                            <Header/>
                            {
                                (branch.id) ?
                                    <BranchContext.Provider value={{branch: branch.id, city: city.id}}>
                                        <Routes>
                                            <Route path="/" element={<Home/>}/>
                                            <Route path="/reviews" element={<ReviewList/>}/>
                                            <Route path="/reviews/:pk" element={<ReviewItem/>}/>
                                            <Route path="/news" element={<NewsList ttp={'news'}/>}/>
                                            <Route path="/news/:slug" element={<NewsItem ttp={'news'}/>}/>
                                            <Route path="/article" element={<NewsList ttp={'article'}/>}/>
                                            <Route path="/article/:slug" element={<ArticleItem ttp={'article'}/>}/>
                                            <Route path="/contacts" element={<Contacts/>}/>
                                            <Route path="/vacancy" element={<Vacancy/>}/>
                                            <Route path="/employee" element={<EmployeeList/>}/>
                                            <Route path="/schedule" element={<Schedule tp={20}/>}/>
                                            <Route path="/schedule/kindergarten" element={<Schedule tp={10}/>}/>
                                            <Route path="/price" element={<Price tp={20}/>}/>
                                            <Route path="/price/kindergarten" element={<Price tp={10}/>}/>
                                            <Route path="/interior" element={<InteriorList/>}/>
                                            <Route path="/gallery" element={<GalleryList/>}/>
                                            <Route path="/gallery/:slug" element={<GalleryItem/>}/>
                                            {/*<Route path="/city/:slugCity" element={<City/>}/>*/}
                                            <Route path="/city/:slugCity/:slugBranch" element={<Branch/>}/>
                                            <Route path="*" element={<SimplePage/>}/>
                                        </Routes>
                                        <Dialog/>
                                    </BranchContext.Provider>
                                    : null
                            }
                        </StickyContainer>

                        <Cookie/>
                        <Footer/>
                    </Wrapper>
                </div>
            </div>
        </Suspense>
    );
};

export default App;

