import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import SlickSlider from "react-slick";
import {InitialItemEmployee, useGetEmployeesQuery} from "../../../services/employer";
import {cropSrc} from "../../../utils/utils";

interface EmployeeBlockProp {
    title: string,
    limit: number,
    branch: number,
    city: number
}

export const EmployeeBlockItem = (props: { item: ItemEmployee }): JSX.Element => {
    const item = props.item;
    let src_image = "/assets/images/no-avatar-225.jpg"
    if (item.image) {
        src_image = cropSrc(item.image, 225, 225)
    }
    return (
        <div className={'wrap'}>
            <div className="vakancy__personal-box">
                <img src={src_image} alt="" className="vakancy__personal-img"/>
                <div className="vakancy__personal-name">{item.name}</div>
                <p>{item.text}</p>
                {
                    (item.experience) ? <div className="vakancy__personal-status">Общий. стаж работы / пед.
                        стаж: {item.experience}</div> : null
                }
                {
                    (item.education) ?
                        <div className="vakancy__personal-status">Образование: {item.education}</div> : null
                }
                {
                    (item.branch) ? <div className="vakancy__personal-status">Филиал: {item.branch.name}</div> : null
                }
            </div>
        </div>

    )
};

export const EmployeeBlock = (props: EmployeeBlockProp): JSX.Element => {
    const {title, limit, branch, city} = props
    const ref = useRef();

    const [employeeList, setEmployeeList] = useState([]);
    const {data: employees, isLoading, isSuccess, isFetching, isError} = useGetEmployeesQuery({
        limit: limit,
        offset: 0,
        city: city
    });

    useEffect(() => {
        if (isSuccess) {
            setEmployeeList([...employees.results]);
        }
    }, [employees, branch]);

    const settings = {
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    const handlerPrev = () => {
        // @ts-ignore
        ref.current.slickPrev()
    };
    const handlerNext = () => {
        // @ts-ignore
        ref.current.slickNext()
    };

    return (
        <React.Fragment>
            <div className="vakancy__personal">
                <h2 dangerouslySetInnerHTML={{__html: title}}/>

                <div className="vakancy__personal-slider slider">
                    {
                        (isSuccess && employeeList.length) ? <SlickSlider ref={ref} {...settings}>

                                {employeeList.map((item) => {
                                    return (
                                        <EmployeeBlockItem key={item.id} item={item}/>
                                    );
                                })}


                            </SlickSlider> :
                            <SlickSlider ref={ref} {...settings}>
                                <EmployeeBlockItem item={InitialItemEmployee}/>
                            </SlickSlider>
                    }
                </div>

                <div className="flex direction">

                    {
                        (ref && ref.current) ?
                            <div className="vakancy__personal-arrow flex">
                                <button className="prev slick-arrow" onClick={() => handlerPrev()}/>
                                <button className="next slick-arrow" onClick={() => handlerNext()}/>
                            </div> : null
                    }

                </div>
            </div>
        </React.Fragment>

    );

};

EmployeeBlock.defaultProps = {
    title: 'Мы гордимся нашим дружным коллективом <br />и всегда рады новым лицам',
    limit: 30,
}


