import {api} from './api';
import {RootState} from "../config/store";

interface CityArg {
    url?: string,
    slug?: string
}

export const defCoordMap = [50.262616, 127.564894]
export const defCoordMapStr = defCoordMap.join(',')
export const InitialBranchCity = {
    id: 0,
    name: '',
    city: 0,
    phone: '',
    slug: '',
    social: {
        telegram: '',
        whatsapp: '',
        vk: '',
        instagram: '',
        youtube: '',
    },
    address: '',
    map: defCoordMapStr,
    email: '',
    work: ''
}

export const postsApi = api.injectEndpoints({
    endpoints: build => ({
        getCity: build.query<Array<ItemCity>, void | CityArg>({
            query: (param: CityArg) => `/core/city/?fields=id,name,main,branch,c_branch,slug`,
            providesTags: data =>
                data
                    ? [
                        ...data.map(({id}) => ({
                            type: 'Core' as const,
                            id,
                        })),
                        {type: 'Core', id: 'CITY-LIST'},
                    ]
                    : [...[], {type: 'Core', id: 'CITY-LIST'}],
        }),
        getCityBySlug: build.query<ItemCity, void | CityArg>({
            query: ({slug}: CityArg) => `/core/city/${slug}/?fields=id,name,slug,c_branch,branch,branches&expand=branches`,
            providesTags: data => [{ type: 'Core', id: 'CITY-ITEM' }]
        }),
        getBranch: build.query<Array<BranchCity>, void | BranchArg>({
            query: (param: BranchArg) => `/core/branch/?city=${(param.city) ? param.city : ''}&fields=id,name,city,slug,phone,social,address,map,email,work`,
            providesTags: data =>
                data
                    ? [
                        ...data.map(({id}) => ({
                            type: 'Core' as const,
                            id,
                        })),
                        {type: 'Core', id: 'BRANCH-CITY-LIST'},
                    ]
                    : [...[], {type: 'Core', id: 'BRANCH-CITY-LIST'}],
        }),
        getBranchBySlug: build.query<BranchCity, void | CityArg>({
            query: ({slug}: CityArg) => `/core/branch/${slug}/?fields=id,name,city,slug,phone,social,address,map,email,work&expand=city`,
            providesTags: data => [{ type: 'Core', id: 'BRANCH-ITEM' }]
        }),
        getAllBranch: build.query<Array<BranchCity>, void | BranchArg>({
            query: () => `/core/branch/?fields=id,name,city,slug,phone,social,address,map,email,work&expand=city`,
            providesTags: data =>
                data
                    ? [
                        ...data.map(({id}) => ({
                            type: 'Core' as const,
                            id,
                        })),
                        {type: 'Core', id: 'BRANCH-CITY-LIST'},
                    ]
                    : [...[], {type: 'Core', id: 'BRANCH-CITY-LIST'}],
        }),
    }),
});

export const {useGetCityQuery, useGetBranchQuery, useGetAllBranchQuery, useGetCityBySlugQuery, useGetBranchBySlugQuery} = postsApi;

export const citiesList = (state: RootState) => state.restApi;

export const {
    endpoints: {getCity, getBranch},
} = postsApi;
