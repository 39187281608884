import React from "react";

interface CountBranchProp {
    countBranch: number,
}

export const CountBranch = (props: CountBranchProp): JSX.Element => {
    const countBranch = props.countBranch
    if (countBranch) {
        return (
            <i className={'t_gradient'}>
                {countBranch} филиал
                {
                    (countBranch < 5 && countBranch > 1) ? 'а' :
                        <React.Fragment>{(countBranch >= 5) ? 'ов' : ''}</React.Fragment>
                }
            </i>

        );
    }

}
