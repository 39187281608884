import React from "react";
import {DialogThanks, DialogThanksProp} from "./DialogThanks";
import {Link} from "react-router-dom";
import {toggleDialog} from "../dialogSlice";
import {useAppDispatch} from "../../../hooks/useAppDispatch";

export const ReviewDialogThanks = (props: DialogThanksProp): JSX.Element => {
    const dispatch = useAppDispatch();
    return (
        <DialogThanks name={props.name} forName={'Ваш отзыв принят'}
                      children={<div className={'thanks-for-review__text'}>После проверки он будет опубликован в разделе <Link to="/reviews" onClick={(e) => {
                        dispatch(toggleDialog())
                    }}>отзывы</Link></div>}
        />

    );
}
