import * as React from 'react';
import {useEffect, useState} from 'react';
import SlickSlider from "react-slick";
import {useGetReviewsQuery} from "../../../services/reviews";
import {toggleReviewDialog} from "../../dialog/dialogSlice";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {cropSrc} from "../../../utils/utils";
import {useAppInview} from "../../../hooks/useAppInView";
import clsx from "clsx";

export const ReviewBlockItem = (props: { item: ItemReview }): JSX.Element => {
    const item = props.item;
    const getLink = (item: ItemReview) => {
        return `/reviews/${item.id}`
    };
    const link = getLink(item)
    let src_image = "/assets/images/no-avatar.jpg"
    if (item.image) {
        src_image = cropSrc(item.image, 90, 90)
    } else {
        if (item.tp.value === 20) {
            src_image = "/assets/images/2gis-avatar.jpg"
        }
    }
    const truncate = (text: string, num = 250) => {
        return text.length > num ? text.substring(0, num - 3) + "..." : text;
    }
    return (
        <div className={'wrap'}>
            <div className="headresporence__block">
                <div className="headresporence__block-head flex align-item">
                    <div className="headresporence__block-avatar">
                        <img src={src_image} alt={`Отзыв ${item.name}`}/>
                    </div>

                    <div className="headresporence__block-name">{item.name}</div>
                </div>
                <p>{truncate(props.item.text)}</p>
                <a href={link} className="more">Читать далее</a>
            </div>
        </div>

    )
};

interface ReviewBlockProp {
    title: string,
    limit: number,
    branch?: number,
    city: number
}


export const ReviewBlock = (props: ReviewBlockProp): JSX.Element => {
    const dispatch = useAppDispatch();
    const {title, limit, branch, city} = props
    const [reviewsList, setReviewsList] = useState([]);
    const {data: reviews, isLoading, isSuccess, isFetching, isError} = useGetReviewsQuery({
        limit: limit,
        offset: 0,
        branch: branch,
        city: city
    });

    useEffect(() => {
        if (isSuccess) {
            setReviewsList([...reviews.results]);
        }
    }, [reviews, branch]);

    const settings = {
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        // lazyLoad: "ondemand",
        responsive: [
            {
                breakpoint: 1040,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    const handleReview = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        dispatch(toggleReviewDialog());
    };

    const { ref, inView } = useAppInview();
    return (
        <div className="headresporence">
            <div className="wrapper">
                <div className="headresporence__header flex space-between">
                    <h2 ref={ref} className={clsx("animate animateY", inView && 'animated')}>{title}</h2>

                    <a href="" className="headresporence__header-more" onClick={handleReview}>Оставить отзыв</a>
                </div>
                <div className={clsx("headresporence-slider slider animate animateY", inView && 'animated')}>
                    {
                        (isSuccess) ? <SlickSlider {...settings}>
                            {reviewsList.map((reviewItem) => {
                                return (
                                    <div key={reviewItem.id}>
                                        <ReviewBlockItem item={reviewItem}/>
                                    </div>
                                );
                            })}
                        </SlickSlider> : null
                    }

                </div>
            </div>
        </div>
    );
};
ReviewBlock.defaultProps = {
    title: 'Родители и дети о саде',
    limit: 30,
}

