import * as React from 'react';

export const Loader = (): JSX.Element => {
    return (
        <div className={"wrapper-loading"}>
            <svg width="80" height="80" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#ed2024"
                 data-testid="three-dots-svg">
                <circle cx="15" cy="15" r="15" fill="#ed2024">
                    <animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15"
                             calcMode="linear" repeatCount="indefinite"></animate>
                    <animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1"
                             calcMode="linear" repeatCount="indefinite"></animate>
                </circle>
                <circle cx="60" cy="15" r="9" attributeName="fill-opacity" from="1" to="0.3" fill={"#fcee1e"}>
                    <animate attributeName="r" from="9" to="9" begin="0s" dur="0.8s" values="9;15;9" calcMode="linear"
                             repeatCount="indefinite"></animate>
                    <animate attributeName="fill-opacity" from="0.5" to="0.5" begin="0s" dur="0.8s" values=".5;1;.5"
                             calcMode="linear" repeatCount="indefinite"></animate>
                </circle>
                <circle cx="105" cy="15" r="15" fill={"#0fa4de"}>
                    <animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15"
                             calcMode="linear" repeatCount="indefinite"></animate>
                    <animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1"
                             calcMode="linear" repeatCount="indefinite"></animate>
                </circle>
            </svg>
        </div>
    );
};


