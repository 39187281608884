import * as React from 'react';
import {Link} from "react-router-dom";
import Moment from "moment";
import {resizeSrc} from "../../utils/utils";

export const NewsBlockItem = ({item, className, ttp}: { item: ItemNews, className: any, ttp: string }): JSX.Element => {
    const getPath = (item: ItemNews, ttp: string) => {
        return `/${ttp}/${item.slug}`
    };
    const path = getPath(item, ttp)
    return (
        <article className="new-news-list__item">
            <div className="new-news-list__img-wrapper">
                <img src={resizeSrc(item.image, 250, 150)} alt={item.name}/>
            </div>
            <div className="new-news-list__text-content">
                <time className="new-news-list__date" dateTime="2022-08-24T16:29">{Moment(item.created).format('DD MMMM YYYY')}</time>
                <h2 className="new-news-list__title"><Link to={path}>{item.name}</Link></h2>
                <p className="new-news-list__excerpt">{item.text}</p>
            </div>
        </article>
    )
};