import * as React from 'react';
import {Link} from "react-router-dom";
import Moment from "moment";
import {resizeSrc} from "../../utils/utils";
import {useAppInview} from "../../hooks/useAppInView";
import clsx from "clsx";

export const ArticleBlockItem = ({item, className, ttp}: { item: ItemNews, className: any, ttp: string }): JSX.Element => {
    const getPath = (item: ItemNews, ttp: string) => {
        return `/${ttp}/${item.slug}`
    };
    const path = getPath(item, ttp)

    const { ref, inView } = useAppInview();
    return (
        <div ref={ref} className={className}>
            <div className={clsx("headnews__block-img animate animateX", inView && 'animated')}>
                <img src={resizeSrc(item.image, 340, 200)} alt={item.name}/>
            </div>

            <div className={clsx("headnews__block-info animate animateX", inView && 'animated')}>
                <h3><Link to={path}>{item.name}</Link></h3>

                <div className="headnews__block-head flex">
                    <div className="headnews__block-data">{Moment(item.created).format('DD MMMM YYYY')}</div>
                    <div className="headnews__block-category">{item.tp.display}</div>

                </div>

                <p>{item.text}</p>
                <div className="flex">
                    <Link to={path} className="more">Читать далее</Link>
                    {
                        (item.branch) ? <div className="branch">{item.branch.name}</div>: null
                    }
                </div>
            </div>
        </div>
    )
};
