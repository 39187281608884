import * as React from 'react';
import {FormResume} from "../../base/form/FormResume";

interface VacancyDialogProp {
    pid: number,
    params: ItemVacancy
}

export const VacancyDialog = (props: VacancyDialogProp): JSX.Element => {
    const {pid, params: vacancy} = props
    return (
        <div className={"leave-comment__wrapper modal-choose-forms bg"}>
            <div className="vakancy__form">
                <h2>Откликнуться на вакансию <br/>«{vacancy.name}»</h2>
                <FormResume vacancy={pid} prefix={`modal-${pid}`}/>
            </div>
        </div>
    );
};


