import {api} from './api';

export const postsApi = api.injectEndpoints({
    endpoints: build => ({
        getInterior: build.query<ListResponse<ItemInterior>, void | QueryArg>({
            query: ({
                        limit,
                        branch,
                        url
                    }: QueryArg) => {
                if (url) {
                    return url
                }
                return `/about/interior?limit=${limit}&branch=${branch}`
            },
            providesTags: data =>
                data
                    ? [
                        ...data.results.map(({id}) => ({
                            type: 'Interior' as const,
                            id,
                        })),
                        {type: 'Interior', id: 'INTERIOR-LIST'},
                    ]
                    : [...[], {type: 'Interior', id: 'INTERIOR-LIST'}],
        }),
    }),
});

export const {useGetInteriorQuery} = postsApi;

export const {
    endpoints: {getInterior},
} = postsApi;
