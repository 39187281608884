import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState } from '../../config/store';

interface InitialState {
  isOpen: boolean;
  component: any
  title: string;
  id?:string,
  pid?:number,
  params?: any,
  name?: string
}

const initialState: InitialState = {
  isOpen: false,
  title: '',
  component: '',
  id: '',
  params: {}
};

interface Action {
  component?: string,
  title: string,
  id?:string
}

interface ActionPid {
  pid?:number,
  params?: any,
  isOpen?: boolean,
  name?: string,
  component?: string,
}

interface ActionTp {
  tp?:number,
}

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    toggleDialog: (state, action: PayloadAction<Action>) => {
      state.isOpen = !state.isOpen;
      if(action.payload) {
        state.component = action.payload.component;
        state.title = action.payload.title;
        state.id = action.payload.id;
      }
    },
    toggleCityDialog: (state, action: PayloadAction<ActionPid>) => {
      state.isOpen = !state.isOpen;
      state.component = 'CityDialog';
      state.title = 'Выберите ближайший к Вам центр';
      state.id = 'city-modal modal-city';
      if(action.payload) {
        if (action.payload.isOpen!=undefined){
          state.isOpen = action.payload.isOpen
        }
        state.pid = action.payload.pid;
        state.params = action.payload.params;
      }
    },
    toggleTimeTableDialog: (state, action:PayloadAction<ActionTp>) => {
      state.isOpen = !state.isOpen;
      state.component = 'TimeTableDialog';
      state.id = 'modal-lessons';
      state.title=null;
      if(action.payload) {
        state.params = action.payload.tp;
      }
    },
    togglePriceTableDialog: (state, action:PayloadAction<ActionTp>) => {
      state.isOpen = !state.isOpen;
      state.component = 'PriceDialog';
      state.id = 'modal-lessons';
      state.title=null;
      if(action.payload) {
        state.params = action.payload.tp;
      }
    },
    toggleLessonDialog: (state, action:PayloadAction<ActionTp>) => {
      state.isOpen = !state.isOpen;
      state.component = 'LessonDialog';
      state.id = 'modal-fill-form';
      state.title=null;
      if(action.payload) {
        state.params = action.payload.tp;
      }
    },
    toggleServiceDialog: (state, action:PayloadAction<ActionPid>) => {
      state.isOpen = !state.isOpen;
      state.component = 'ServiceDialog';
      state.id = 'modal-choose';
      state.title=null;
      if(action.payload) {
        if (action.payload.isOpen!=undefined){
          state.isOpen = action.payload.isOpen
        }
        state.pid = action.payload.pid;
        state.params = action.payload.params;
      }
    },
    toggleServiceDialog2: (state, action:PayloadAction<ActionPid>) => {
      state.isOpen = !state.isOpen;
      state.component = 'ServiceDialog2';
      state.id = 'modal-fill-form';
      state.title=null;

      if(action.payload) {
        if (action.payload.isOpen!=undefined){
          state.isOpen = action.payload.isOpen
        }
        state.pid = action.payload.pid;
        state.params = action.payload.params;
      }
    },
    toggleDialogThank: (state, action:PayloadAction<ActionPid>) => {
      state.isOpen = !state.isOpen;
      state.component = 'DialogThanks';
      state.id = 'thanks-for-review thanks-for-review--data';
      state.title=null;

      if(action.payload) {
        if (action.payload.isOpen!=undefined){
          state.isOpen = action.payload.isOpen
        }
        if (action.payload.component){
          state.component = action.payload.component;
        }
        state.pid = action.payload.pid;
        state.params = action.payload.params;
        state.name = action.payload.name
      }
    },
    toggleFreeLessonDialog: (state) => {
      state.isOpen = !state.isOpen;
      state.component = 'FreeLessonDialog';
      state.id = 'modal-class';
      state.title=null;
    },
    toggleExcursionDialog: (state) => {
      state.isOpen = !state.isOpen;
      state.component = 'ExcursionDialog';
      state.id = 'modal-excursion';
      state.title=null;
    },
    toggleConsDialog: (state) => {
      state.isOpen = !state.isOpen;
      state.component = 'ConsDialog';
      state.id = 'modal-callback';
      state.title=null;
    },
    toggleCallBackDialog: (state) => {
      state.isOpen = !state.isOpen;
      state.component = 'CallBackDialog';
      state.id = 'modal-callback';
      state.title=null;
    },
    toggleReviewDialog: (state) => {
      state.isOpen = !state.isOpen;
      state.component = 'ReviewDialog';
      state.id = 'modal-choose leave-comment';
      state.title=null;
    },
    toggleMailDialog: (state) => {
      state.isOpen = !state.isOpen;
      state.component = 'MailDialog';
      state.id = 'modal-choose leave-comment';
      state.title=null;
    },
    toggleVacancyDialog: (state, action:PayloadAction<ActionPid>) => {
      state.isOpen = !state.isOpen;
      state.component = 'VacancyDialog';
      state.id = 'modal-choose leave-comment';
      if(action.payload) {
        state.pid = action.payload.pid;
        state.params = action.payload.params;
      }
    },
  },
});

export const { toggleDialog, toggleCityDialog, toggleServiceDialog, toggleServiceDialog2, toggleDialogThank, toggleTimeTableDialog, toggleLessonDialog, togglePriceTableDialog, toggleFreeLessonDialog, toggleExcursionDialog, toggleConsDialog, toggleCallBackDialog, toggleReviewDialog, toggleMailDialog, toggleVacancyDialog } = dialogSlice.actions;

export const isOpen = (state: RootState) => state.dialog.isOpen;
export const paramsDialog = (state: RootState) => state.dialog;


export default dialogSlice.reducer;
