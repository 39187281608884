import * as React from 'react';
import {useEffect, useState} from 'react';
import SlickSlider from "react-slick";
import {useGetPhotosQuery} from "../../../services/gallery";
import FsLightbox from 'fslightbox-react';
import {Link} from "react-router-dom";
import {cropSrc} from "../../../utils/utils";
import {useAppInview} from "../../../hooks/useAppInView";
import clsx from "clsx";


export const PhotoBlockItem = (props: { item: ItemPhoto, onClick: React.MouseEventHandler }): JSX.Element => {
    const item = props.item;
    const src_image = cropSrc(item.image, 400, 400)
    return (
        <div className={'wrap'}>
            <a href={item.image} onClick={props.onClick}>
                <img src={src_image} alt={item.name}/>
            </a>
        </div>
    )
};

interface GalleryBlockProp {
    title: string,
    limit: number,
    city: number,
    branch?: number
}


export const PhotoBlock = (props: GalleryBlockProp): JSX.Element => {
    const {title, limit, city, branch} = props
    const [photosList, setList] = useState([]);
    const {data: photos, isLoading, isSuccess, isFetching, isError} = useGetPhotosQuery({
        limit: limit,
        offset: 0,
        city: city,
        branch: branch
    });
    const [toggler, setToggler] = useState(false);
    const [slide, setSlide] = useState(1);
    useEffect(() => {
        if (isSuccess) {
            setList([...photos.results]);
        }
    }, [photos]);

    const settings = {
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        responsive: [
            {
                breakpoint: 1040,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    const { ref, inView } = useAppInview();
    return (
        <div className="headgallery">
            <div className="wrapper">
                <div ref={ref} className="headgallery__header flex space-between">
                    <h2 className={clsx("animate animateY", inView && 'animated')}>{title}</h2>
                    <Link to="gallery" className={clsx("headgallery__header-more animate animateY hide-lsee-lg hide-lsee-md", inView && 'animated')}>Смотрите больше</Link>
                </div>
                {
                    (isSuccess) ?
                        <FsLightbox toggler={toggler} slide={slide}
                                    sources={[...photosList.map((photoItem) => photoItem.image)]} type={'image'}/>
                        : null
                }
                <div className={clsx("headgallery-slider slider animate animateY", inView && 'animated')}>
                    {
                        (isSuccess) ? <SlickSlider {...settings}>
                            {photosList.map((photoItem, index) => {
                                return (
                                    <div key={photoItem.id}>
                                        <PhotoBlockItem item={photoItem} onClick={(e) => {
                                            e.preventDefault();
                                            setSlide(index + 1);
                                            setToggler(!toggler);
                                        }}/>
                                    </div>
                                );
                            })}
                        </SlickSlider> : null
                    }

                </div>
            </div>
        </div>
    );
};
PhotoBlock.defaultProps = {
    title: 'Посмотрите, как у нас здорово',
    limit: 30,
}

