import React from "react";
import {useLocation} from "react-router-dom";
import {useGetPageByUrlQuery} from "../../services/flatepage";
import {Meta} from "./Meta";

export const MetaPage = (): JSX.Element => {
    const {pathname} = useLocation();
    const {data: simplePage, isSuccess} = useGetPageByUrlQuery({url: pathname});
    let metatags = {}
    if (isSuccess && simplePage) {
        metatags = simplePage.metatags
    }
    return <Meta {...metatags} />
}
