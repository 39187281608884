import * as React from 'react';
import {useEffect, useState} from 'react';
import {useBranchContext} from "../../context/BranchContext";
import {cropSrc} from "../../utils/utils";
import {SimpleBlock} from "../simple/SimpleBlock";
import SelectBranch from "../base/form/SelectBranch";
import {useGetAlbumsQuery} from "../../services/gallery";
import Moment from "moment";
import {EmployeeBlock} from "../vacancy/components/EmployeeBlock";
import {VacancyBlock} from "../vacancy/components/VacancyBlock";
import {Loader} from "../loader/Loader";
import {BreadCrumbsBlock} from "../simple/BreadCrumbsBlock";
import {useLocation} from "react-router-dom";

export const AlbumItem = (props: { item: ItemAlbum, }): JSX.Element => {
    const item = props.item;
    const src_image = cropSrc(item.image, 360, 388)

    return (
        <article className="photoalbum-item photoalbum__item">
            <div className="photoalbum-item__wrapper">
                <div className="photoalbum-item__info">
                    <time className="photoalbum-item__time"
                          dateTime={`${Moment(item.updated).format('YYYY-MM-DD')}`}>{Moment(item.updated).format('DD MMMM YYYY')}</time>
                    <div className="photoalbum-item__count">
                        <img src="/assets/images/gallery-count-image.png" alt={item.name}/>
                        <span>{item.c_photos || 0}</span>
                    </div>
                </div>
                <h2 className="photoalbum-item__title"><a href={`/gallery/${item.slug}`}>{item.name}</a></h2>
            </div>
            <div className="photoalbum-item__image">
                <img src={src_image} alt={item.name}/>
            </div>
        </article>
    )
};

const GalleryList = (props: PageListProp): JSX.Element => {
    const {pathname} = useLocation();
    let {title, limit} = props
    const {branch, city} = useBranchContext();

    const [nextUrl, setNextUrl] = React.useState('');
    const [currentUrl, setCurrentUrl] = React.useState('');
    const [objectsList, setList] = useState([]);
    const {data: objects, isLoading} = useGetAlbumsQuery({
        limit: limit,
        city: city,
        url: currentUrl
    });


    useEffect(() => {
        setCurrentUrl('');
    }, [branch]);

    useEffect(() => {
        if (objects) {
            if (currentUrl) {
                setList([...objectsList, ...objects.results]);
            } else {
                setList([...objects.results]);
            }
            setNextUrl(objects.next);
        }
    }, [objects]);

    return (
        <React.Fragment>
            <div className="container">
                <div className="wrapper">
                    {
                        (isLoading) ? <Loader/> :
                    <div className="photoalbum__section">
                        <SimpleBlock title={title} bread_name={title} children_bread={
                            <></>
                        }/>
                        <div className="photoalbum__list">
                            {objectsList.map((albumItem) => {
                                return (
                                    <React.Fragment key={albumItem.id}>
                                        <AlbumItem item={albumItem}/>
                                    </React.Fragment>
                                );
                            })}
                        </div>
                        {nextUrl && (
                            <div className={'headanswer-more-block mt-20'}>
                                <button
                                    className='headanswer-more'
                                    onClick={() => setCurrentUrl(nextUrl)}
                                >
                                    Показать еще
                                </button>
                            </div>
                        )}
                    </div>
                            }
                </div>
            </div>
        </React.Fragment>
    );
};

export default GalleryList;

const title = 'Фотогалерея'
GalleryList.defaultProps = {
    title: title,
    limit: 30,
    metatags: {
        title: title
    }
}
