import * as React from 'react';
import {useBranchContext} from "../../context/BranchContext";
import {EmployeeBlock} from "./components/EmployeeBlock";
import {VacancyBlock} from "./components/VacancyBlock";
import {MetaPage} from "../base/MetaPage";
import {SimpleBlock} from "../simple/SimpleBlock";
import {useAppSelector} from "../../hooks/useAppSelector";
import {mainBranch} from "../dialog/components/cityReducer";
import {PrivacyForm} from "../base/form/PrivacyForm";
import {FormResume} from "../base/form/FormResume";


const Vacancy = (): JSX.Element => {
    const {branch, city} = useBranchContext();
    const branch_obj = useAppSelector(mainBranch);
    return (
        <React.Fragment>
            <MetaPage/>
            <div className="container">
                <div className="wrapper">
                    <div className="vakancy">
                        <SimpleBlock title={'О компании для соискателей'} children={
                            <React.Fragment>
                                <EmployeeBlock branch={branch} city={city}/>
                                <VacancyBlock branch={branch} city={city}/>
                            </React.Fragment>
                        }/>

                        <div className="vakancy__form">
                            <h2>Нет подходящей вакансии?</h2>

                            <div className="vakancy__form-title">Напишите нам. Мы всегда ищем лучших специалистов!</div>
                            <FormResume prefix={'main-form'}/>

                            <div className="vakancy__form-note">70% людей не уверенны в своих силах и просто закроют эту
                                вакансию.
                            </div>
                            <div className="vakancy__form-note">27% сбросят свою резюме «а вдруг»</div>
                            <div className="vakancy__form-note">И только 3% сами позвонят по
                                телефону {branch_obj.phone} и
                                запишутся на собеседование!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Vacancy;
