import * as React from 'react';
import {Link} from "react-router-dom";
// import '../../assets/css/error.scss';
import {Meta} from "../base/Meta";

export const PageNotFound = (): JSX.Element => {
    const metatags = {title: 'Упс. Такой страницы нет на topkids-center.ru'}
  return (
      <div className="error-page">
          <Meta {...metatags} />
          <div className="wrapper">
              <div className="error-page__header">
                  <div className="error-page__pic">
                      <img src='/assets/images/broken-pen.png' alt="404"/>
                  </div>
                  <h1 className="error-page__title">Упс</h1>
              </div>
              <p className="error-page__text">
                  Такой страницы <br/>
                  нет на <a href="/">topkids-center.ru</a>
              </p>
              <Link className="btn btn-yellow error-page__comeback" to="/">Вернуться на главную </Link>
          </div>
      </div>
  );
};
