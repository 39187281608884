import {api} from './api';

export const postsApi = api.injectEndpoints({
    endpoints: build => ({
        getPageByUrl: build.query<ItemPage, void | QueryUrlArg>({
            query: ({url, omit}: QueryUrlArg) => `/simple/page/?url=${url}&omit=${(!omit) ? 'slider': ''}`,
            providesTags: data => [{type: 'Page', id: 'PAGE-ITEM'}]
        }),
    }),
});

export const {useGetPageByUrlQuery} = postsApi;

export const {
    endpoints: {getPageByUrl},
} = postsApi;
