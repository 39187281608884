import {api} from './api';

export const postsApi = api.injectEndpoints({
    endpoints: build => ({
        getFaq: build.query<ListResponse<ItemFaq>, void | QueryArg>({
            query: ({offset, limit, url, city, branch}:QueryArg) => {
                if (url){
                    return url
                }
                return `/about/faq?offset=${offset}&limit=${limit}&city=${city}&branch=${(branch) ? branch: ''}`
            },
            providesTags: data =>
                data
                    ? [
                        ...data.results.map(({id}) => ({
                            type: 'Faq' as const,
                            id,
                        })),
                        {type: 'Faq', id: 'FAQ-LIST'},
                    ]
                    : [...[], {type: 'Faq', id: 'FAQ-LIST'}],
        }),
    }),
});

export const {useGetFaqQuery} = postsApi;

export const {
    endpoints: {getFaq},
} = postsApi;
