import * as React from 'react';
import {useEffect, useState} from 'react';

import {useGetNewsQuery} from '../../../services/news';
import clsx from "clsx";
import {Link} from "react-router-dom";
import {ArticleBlockItem} from "../../news/ArticleBlockItem";
import {useAppInview} from "../../../hooks/useAppInView";


interface NewsBlockProp {
    title: string,
    limit: number,
    city: number,
    branch?: number,
}

export const NewsBlock = (props: NewsBlockProp): JSX.Element => {
    const ttp = 'news'
    const {title, limit, city, branch} = props
    const [newsList, setNewsList] = useState([]);
    const {data: news, isLoading, isSuccess, isFetching, isError} = useGetNewsQuery({
        limit: limit,
        offset: 0,
        city: city,
        branch: branch,
        ttp: ttp
    });


    useEffect(() => {
        if (isSuccess) {
            setNewsList([...news.results]);
        }
    }, [city, news]);

    const { ref, inView } = useAppInview();

    return (
        <div className="headnews">
            <div className="wrapper">
                <div ref={ref} className="headnews__header flex space-between">
                    <h2 className={clsx("animate animateY", inView && 'animated')}>{title}</h2>
                    <Link to="/news" className={clsx("headnews__header-more animate animateY", inView && 'animated')}>Смотрите больше</Link>
                </div>
                <React.Fragment>
                    {newsList.map((newsItem) => {
                        return (
                            <div key={newsItem.id} className='news-grid__content'>
                                <ArticleBlockItem item={newsItem}
                                                  className={clsx("headnews__block flex", isLoading && 'is-loading')}
                                                  ttp={ttp}/>
                            </div>
                        );
                    })}
                </React.Fragment>
            </div>
        </div>
    );
};
NewsBlock.defaultProps = {
    title: 'Акции и новости',
    limit: 2,
}

