import {api} from './api';

export const postsApi = api.injectEndpoints({
    endpoints: build => ({
        getPhotos: build.query<ListResponse<ItemPhoto>, void | QueryArg>({
            query: (param: QueryArg) => `/about/photos/?offset=${param.offset}&limit=${param.limit}&city=${param.city}&branch=${(param.branch) ? param.branch: ''}`,
            providesTags: data =>
                data
                    ? [
                        ...data.results.map(({id}) => ({
                            type: 'Gallery' as const,
                            id,
                        })),
                        {type: 'Gallery', id: 'PHOTOS-LIST'},
                    ]
                    : [...[], {type: 'Gallery', id: 'PHOTOS-LIST'}],
        }),
        getAlbums: build.query<ListResponse<ItemAlbum>, void | QueryArg>({
            query: ({
                        limit,
                        city,
                        url
                    }: QueryArg) => {
                if (url) {
                    return url
                }
                return `/about/albums/?limit=${limit}&city=${city}&omit=metatags,content,photos`
            },
            providesTags: data =>
                data
                    ? [
                        ...data.results.map(({id}) => ({
                            type: 'Gallery' as const,
                            id,
                        })),
                        {type: 'Gallery', id: 'ALBUM-LIST'},
                    ]
                    : [...[], {type: 'Gallery', id: 'ALBUM-LIST'}],
        }),
        getAlbumsById: build.query<ItemAlbum, void | QuerySlugArg>({
            query: ({slug}: QuerySlugArg) => `/about/albums/${slug}/`,
            providesTags: data => [{ type: 'Gallery', id: 'ALBUM-ITEM' }]
        }),
    }),
});

export const {useGetPhotosQuery, useGetAlbumsQuery, useGetAlbumsByIdQuery} = postsApi;

export const {
    endpoints: {getPhotos, getAlbums, getAlbumsById},
} = postsApi;
