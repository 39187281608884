import * as React from 'react';
import {useAppInview} from "../../../hooks/useAppInView";
import clsx from "clsx";

export const WhyWe = (): JSX.Element => {
    const { ref, inView } = useAppInview();
    return (
        <div className="headitems">
            <div className="wrapper">
                <h2 ref={ref} className={clsx("animate animateY", inView && 'animated')}>Почему именно мы?</h2>

                <div className="headitems__box flex space-between">
                    <div className={clsx("headitems__block animate animateY", inView && 'animated')}>
                        <div className="headitems__block-icon flex align-item"><img src="/assets/images/item-icon1.svg"
                                                                                    alt=""/>
                        </div>
                        <div className="headitems__block-title">Более {(new Date().getFullYear())-2005} лет на рынке</div>
                        <p>За это время отработали собственные методологии обучения</p>
                    </div>

                    <div className={clsx("headitems__block animate animateY", inView && 'animated')}>
                        <div className="headitems__block-icon flex align-item"><img src="/assets/images/item-icon2.svg"
                                                                                    alt=""/>
                        </div>
                        <div className="headitems__block-title">Более 1500 родителей</div>
                        <p>Довольны нашей работой, также как и дети выпускники</p>
                    </div>

                    <div className={clsx("headitems__block animate animateY", inView && 'animated')}>
                        <div className="headitems__block-icon flex align-item"><img src="/assets/images/item-icon3.svg"
                                                                                    alt=""/>
                        </div>
                        <div className="headitems__block-title">Более 12 направлений</div>
                        <p>Для каждого ребёнка найдётся свой вариант занятий</p>
                    </div>
                </div>
            </div>
        </div>
    );
};


