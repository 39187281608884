import * as React from 'react';
import {useBranchContext} from "../../../context/BranchContext";
import {FormReview} from "../../base/form/FormReview";


export const ReviewDialog = (): JSX.Element => {
    const {branch, city} = useBranchContext();
    return (
        <div className={"leave-comment__wrapper modal-choose-forms bg"}>
            <FormReview branch={branch}/>
        </div>
    );
};


