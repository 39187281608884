import * as React from 'react';
import {SimpleBlock} from "../simple/SimpleBlock";
import {SheduleBlock} from "./SheduleBlock";

interface ScheduleProp {
    tp: number,
}

const Schedule = (props: ScheduleProp): JSX.Element => {
    return (
        <div className="container">
            <div className="wrapper">
                <SimpleBlock title={'Расписание занятий'}/>
                <SheduleBlock tp={props.tp} hide_h={true}/>
            </div>
        </div>
    );
};

export default Schedule;
