import {api} from './api';

interface NewsQueryArg extends QueryArg {
    ttp: string;
}

interface NewsQuerySlugArg extends QuerySlugArg {
    ttp: string;
}

export const postsApi = api.injectEndpoints({
    endpoints: build => ({
        getNews: build.query<ListResponse<ItemNews>, void | NewsQueryArg>({
            query: ({offset, limit, city, branch, ttp}: NewsQueryArg) => {
                return `/about/news/?offset=${offset}&limit=${limit}&city=${city}&branch=${(branch) ? branch: ''}&ttp=${(ttp) ? ttp : ''}&omit=content,metatags`
            },
            providesTags: data =>
                data
                    ? [
                        ...data.results.map(({id}) => ({
                            type: 'News' as const,
                            id,
                        })),
                        {type: 'News', id: 'NEWS-LIST'},
                    ]
                    : [
                        ...[], {type: 'News', id: 'NEWS-LIST'}],
        }),
        getNewsById: build.query<ItemNews, void | NewsQuerySlugArg>({
            query: ({slug, ttp}: NewsQuerySlugArg) => `/about/news/${slug}/?omit=text&ttp=${(ttp) ? ttp : ''}`,
            providesTags: data => [{ type: 'News', id: 'NEWS-ITEM' }]
        }),
    }),
});

export const {useGetNewsQuery, useGetNewsByIdQuery} = postsApi;

export const {
    endpoints: {getNews, getNewsById},
} = postsApi;
